import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "/node_modules/primeflex/primeflex.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MainHeader from "./screens/components/MainHeader";
import Header from "./screens/components/Header";
import Timesheet from "./screens/Timesheet";
import TimesheetApproval from "./screens/TimesheetApproval";
import TimesheetDetail from "./screens/TimesheetDetail";
import ContractsManagement from "./screens/ContractsManagement";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "./AuthContext";
import Loading from "./screens/components/Loading";
import { StompSessionProvider } from 'react-stomp-hooks';
import { config } from "./Constants";

function App() {
  const { isAuthenticated, handleLogin, isLoading, token } = useAuth();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      handleLogin();
    }
  }, [isAuthenticated, isLoading, handleLogin]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <StompSessionProvider url={`${config.url.WEBSOCKET_BASE_URL}/ws-endpoint`} headers={{ Authorization: `Bearer ${token}` }}>
      <Router>
        <ToastContainer autoClose={3000} />
        <div className="col-12">
          <MainHeader />
        </div>
        <div className="col-12">
          <Header />
        </div>
        <div className="container-fluid mt-2 px-5">
          <Routes>
            <Route path="/" element={<Timesheet />} />
            <Route path="/timesheetApproval" element={<TimesheetApproval />} />
            <Route path="/timesheetDetail/:timesheetWorkflowId" element={<TimesheetDetail />} />
            <Route path="/contractsManagement" element={<ContractsManagement />} />
          </Routes>
        </div>
      </Router>
    </StompSessionProvider>
  );
}

export default App;
