import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, Table, Form } from "react-bootstrap";
import Select from "react-select";
import { useAuth } from "../AuthContext";
import { timeslotsApi } from "../misc/TimeslotsApi";
import "react-datepicker/dist/react-datepicker.css";
import ModalAddEditContract from "./components/ModalAddEditContract";
import ModalGenerateModel from "./components/ModalGenerateModel"; //

function ContractsManagement() {
  const { i18n, t } = useTranslation();
  const { token, updateToken } = useAuth();
  const [resources, setResources] = useState([]);
  const [selectedResource, setSelectedResource] = useState(null);
  const rootStyles = getComputedStyle(document.documentElement);
  const mainColor = rootStyles.getPropertyValue("--main-color").trim();
  const [showModalAddEditContract, setShowModalAddEditContract] =
    useState(false);
  const [showModalGenerateModal, setShowModalGenerateModal] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [selectedContract, setSelectedContract] = useState(null);
  const [selectedRowContract, setSelectedRowContract] = useState(null); // prova per la selezione della riga

  const openModalAddEditContract = (contract = null) => {
    setSelectedContract(contract);
    setShowModalAddEditContract(true);
  };

  const closeModalAddEditContract = () => {
    setSelectedContract(null);
    setShowModalAddEditContract(false);
  };

  const openModalGenerateModel = (contract=null) => {
    setSelectedContract(contract); //
    setShowModalGenerateModal(true);
    console.log("## contratto selezionato: " + JSON.stringify(contract));
  };

  const closeModalGenerateModel = () => {
    setShowModalGenerateModal(false);
    setSelectedContract(null);
  };

  const formatDate = (dateString) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const date = new Date(dateString);
    return date
      .toLocaleDateString(i18n.language, options)
      .toUpperCase()
      .replace(".", "");
  };

  const fetchResources = useCallback(async () => {
    await updateToken();
    if (token) {
      timeslotsApi
        .getAllResources(token, false)
        .then((response) => {
          const formattedResources = response.data.map((resource) => ({
            value: resource.id,
            label: resource.company
              ? resource.companyName
              : `${resource.name} ${resource.surname}`,
          }));
          setResources(formattedResources);
        })
        .catch((error) => {
          console.error("Error while loading resources:", error);
        });
    }
  }, [token, updateToken]);

  const fetchContracts = useCallback(
    async (resource) => {
      await updateToken();
      if (token && resource) {
        timeslotsApi
          .getAllContractsByResource(resource.value, token)
          .then((response) => {
            setContracts(response.data);
          })
          .catch((error) => {
            console.error("Error while loading contracts:", error);
          });
      }
    },
    [token, updateToken,selectedResource]
  );

  // per la selezione della riga della tabella da utilizzare
  const handleRowClick = (row) => {
    setSelectedRowContract(row);
    console.log("## RIGA SELEZIONATA: " + row);
  }

  useEffect(() => {
    fetchResources();
  }, [fetchResources]);

  return (
    <>
      <div className="row">
        <div className="col-6 text-start">
          <h4 className="fw-semibold">{t("contractsManagement")} </h4>
        </div>
        <div className="row">
          <div className="col-4"></div>
          <div className="col-4">
            <Form.Group controlId="resourceForm">
              <Form.Label className="green-color">{t("resource")}</Form.Label>
              <Select
                classNamePrefix="custom-select"
                placeholder={t("selectResource")}
                value={selectedResource}
                onChange={(value) => {
                  setSelectedResource(value);
                  setContracts([]);
                  fetchContracts(value);
                }}
                options={resources}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: mainColor,
                    primary: mainColor,
                    neutral20: "#dee2e6",
                  },
                })}
              />
            </Form.Group>
          </div>
          <div className="col-4"></div>
        </div>
        <div className="row">
          <div className="col-2"></div>
          {contracts.length === 0 ? (
            <div className="col-8 text-center">
              {selectedResource
                ? t("noContractsByResource")
                : t("selectResourceForContracts")}
            </div>
          ) : (
            <div className="col-8">
              <Table hover striped responsive>
                <thead className="text-center">
                  <tr>
                    <th>{t("job")}</th>
                    <th>{t("type")}</th>
                    <th>{t("startDate")}</th>
                    <th>{t("endDate")}</th>
                    <th>{t("days")}</th>
                    <th>{t("unitCost")}</th>
                    <th>{t("totalCost")}</th>
                    <th colSpan={2}>{t("actions")}</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {contracts.map((contract) => (
                    <tr key={contract.id} onClick={() => handleRowClick(contract)}> 
                      <td>{contract.project?.name || "-"}</td>
                      <td>{contract.contractType?.name || "-"}</td>
                      <td>
                        {contract.startDate
                          ? formatDate(contract.startDate)
                          : "-"}
                      </td>
                      <td>
                        {contract.endDate ? formatDate(contract.endDate) : "-"}
                      </td>
                      <td>{contract.unit?.days || "-"}</td>
                      <td>{contract.unitaryCost}</td>
                      <td>{contract.totalCost}</td>
                      <td>
                        <Button
                          variant="link"
                          className="p-0 green-color"
                          onClick={() => openModalAddEditContract(contract)}
                        >
                          {t("edit")}
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="link"
                          className="p-0 green-color"
                          onClick={() => openModalGenerateModel(contract)}
                        >
                          {t("generateModel")}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
          <div className="col-2"></div>
        </div>
        <div className="container d-flex flex-column justify-content-center align-items-center mt-3">
          <div className="">
            <Button
              className="white-text-button fw-semibold"
              onClick={() => openModalAddEditContract()}
            >
              {t("createContract")}
            </Button>
          </div>
        </div>
      </div>
      <ModalAddEditContract
        show={showModalAddEditContract}
        handleClose={closeModalAddEditContract}
        contract={selectedContract}
        isEditing={selectedContract !== null}
      />
      <ModalGenerateModel
        show={showModalGenerateModal}
        handleClose={closeModalGenerateModel}
        contract={selectedContract}
      />
    </>
  );
}

export default ContractsManagement;