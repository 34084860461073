import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, Form, FormControl, ModalFooter } from "react-bootstrap";
import Select from "react-select";
import { useAuth } from "../../AuthContext";
import { timeslotsApi } from "../../misc/TimeslotsApi";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { enUS, it } from "date-fns/locale";

function ModalGenerateModel({ show, handleClose, contract }) {
  const { i18n, t } = useTranslation();
  const { token, updateToken } = useAuth();
  const rootStyles = getComputedStyle(document.documentElement);
  const mainColor = rootStyles.getPropertyValue("--main-color").trim();
  const [selectedModelType, setSelectedModelType] = useState(null);
  const [models, setModels] = useState(null);
  const [figures, setProfiles] = useState(null);
  const [approvers, setApprovers] = useState(null);
  const [place, setPlace] = useState("");
  const [foreword, setForeword] = useState(""); //premessa
  const [subject, setSubject] = useState(""); //oggetto
  const [selectedProfile, setSelectedProfile] = useState(null); // profilo
  const [notice, setNotice] = useState(""); //preavviso
  const [mode, setMode] = useState(""); //modalita
  const [invoice, setInvoice] = useState(""); //fattura
  const [payment, setPayment] = useState(""); //pagamento
  const [selectedApproves, setSelectedApproves] = useState(null); //approva
  const [kmReimbursement, setKmReimbursement] = useState(""); //rimborso km
  const [board, setBoard] = useState(""); // vitto
  const [docDate, setDocDate] = useState(""); //doc date
  const [switches, setSwitches] = useState({
        contractVariationsActive: false
      });

  const modelOptions = useMemo(() => 
    models?.map(model => ({ value: model.id, label: model.name })) || [], 
    [models]
  );
  
  const figureOptions = useMemo(() => 
    figures?.map(figure => ({ value: figure.id, label: figure.name })) || [], 
    [figures]
  );
  
  const approverOptions = useMemo(() => 
    approvers?.map(approver => ({ value: approver.id, label: approver.name })) || [], 
    [approvers]
  );

  const handleSwitch = (field) => {
    setSwitches((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };
  
  const getLocale = () => {
    switch (i18n.language) {
      case "it":
        return it;
      default:
        return enUS;
    }
  };

  // definizione di formatDate
  const formatDate = (dateString) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const date = new Date(dateString);
    return date
      .toLocaleDateString(i18n.language, options)
      .toUpperCase()
      .replace(".", "");
  };


  const resetModal = useCallback(() => {
    setSelectedModelType(null);
  setPlace("");
  setForeword("");
  setSubject("");
  setSelectedProfile(null);
  setNotice("");
  setMode("");
  setInvoice("");
  setPayment("");
  setSelectedApproves(null);
  setKmReimbursement("");
  setBoard("");
  setDocDate(new Date());
  }, []);

  useEffect(() => {
    if (!show) {
      resetModal();
      return;
    }
    const fetchAllData = async () => {
      
          await updateToken();
          if (token && contract?.project?.id) {
            try {
              const [modelsRes, prefsRes,figsRes] = await Promise.all([
                timeslotsApi.getAllModels(token),
                timeslotsApi.getProjectPreferencesByProject(contract.project.id,token),
                timeslotsApi.getAllFigures(token)
              ]);

              setModels(modelsRes.data);
              setProfiles(figsRes.data);
              setApprovers(figsRes.data);
              

              const preferences=prefsRes.data;

              setForeword(preferences.find(pref=> pref.preference.id==="CONTRACT_PREMISE_TEXT").typeText);
              setSubject(preferences.find(pref => pref.preference.id==="CONTRACT_SUBJECT_TEXT").typeText);
              setMode(preferences.find(pref => pref.preference.id==="CONTRACT_MODE_TEXT").typeText);
              setNotice(preferences.find(pref => pref.preference.id==="CONTRACT_NOTICE_STRING").typeString);
              setInvoice(preferences.find(pref => pref.preference.id==="CONTRACT_INVOICE_STRING").typeString);
              setPayment(preferences.find(pref => pref.preference.id==="CONTRACT_PAYMENT_STRING").typeString);
              setBoard(preferences.find(pref => pref.preference.id==="CONTRACT_BOARD_DECIMAL").typeDecimal);
              setKmReimbursement(preferences.find(pref => pref.preference.id==="CONTRACT_KM_DECIMAL").typeDecimal);

              setSelectedProfile({value:contract?.projectFigure?.figure?.id,label:contract?.projectFigure?.figure?.name});
            } catch (error) {
              console.error("Error while loading initial data:", error);
            }
          }
        };
      
        fetchAllData();
  }, [token,updateToken, show, resetModal]);

  useEffect(() => {
      
    }, [show, resetModal]);


    const convNumLett= function (n, abbr) {
      if(isNaN(n) === true){
        return "!!! Insert number !!!";
      }
      if(n.indexOf('.') >=0){
        return "!!! Only integer number !!!";
      }
      if(n === 0){
        return "ZERO";
      }
      var lettu = ["","uno","due","tre","quattro","cinque","sei","sette","otto","nove"];
      var lettu1 = ["dieci","undici","dodici","tredici","quattordici","quindici","sedici","diciassette","diciotto","diciannove"];
      var lettd = ["","dieci","venti","trenta","quaranta","cinquanta","sessanta","settanta","ottanta","novanta"];
      var prefs = ["","unmila","unmilione","unmiliardo"];
      var prefp = ["","mila","milioni","miliardi"];
       
      var numero = n.replace(/^\s+|\s+$/g,""); 
      var lmax = numero.length;
     
      //indica se centinaia=2, decine=1 o unita=0
      var cdu = parseInt((lmax-1) % 3);
      //indica il moltiplicatore
      var molt = parseInt((lmax-1) / 3);
      if(molt > 3){
        molt = parseInt(molt % 4);
      }
       
      var risultato = "";    //contiene il numero in lettere
      var i  = 0;   //contatore all'interno del numero
     
      while(i < lmax){
        var ris_par = "";
        var cent_dec = 0;  //se=1, indica se nelle centinaia o nelle decine c'era una cifra <> da 0
        var decine = 0;    //se=1, indica che nelle decine c'era un 1
        if(i < lmax-3 && molt < 1){
          molt = 3;
        }
        var car = parseInt(numero.charAt(i));
        //* centinaia
        if( cdu == 2 ){
          if (car > 0){
            if( car != 1){
              ris_par = lettu[car] + "cento";
            }else{
              ris_par =  "cento";
            }
            cent_dec = 1;
          }
          i++;
        } 
        // decine
        car = parseInt(numero.charAt(i));
        if( cdu >= 1) {
          if( car == 1){
            decine = 1;
          } else if(car > 1){
            ris_par = ris_par + lettd[car];
            cent_dec = 2;
          }
          i++;
        }
        // unita
        car = parseInt(numero.charAt(i));
        if( cdu >= 0) {
          if( decine == 1){
            //scrive numero da 10 a 19
            ris_par = ris_par + lettu1[car];
            cent_dec = 1;
          }else {
            // scrive gli altri numeri 0 a 9
            if ((car == 1 || car == 8) && cent_dec == 2){
              ris_par = ris_par.substring(0, ris_par.length-1);
            }
            if ((car == 1 && cent_dec > 0) || car > 1 || molt == 0 ){
              ris_par = ris_par + lettu[car];
            }
          }
          if (decine == 1 || cent_dec > 0 || car != 0 || molt == 3){
            if (cent_dec == 0 && car == 1){
              ris_par = ris_par + prefs[molt];
            }else{
              ris_par = ris_par + prefp[molt];
            }
          }
          if ((ris_par == "unmila" || ris_par == "mila") && i == 0 ){
            ris_par = "mille";
          }
          i++;
          molt--;
          cdu = 2;
          risultato = risultato + ris_par;
          if (abbr == true && i >= lmax-3){
            risultato = risultato + "/" + numero.substring(numero.length-2);
            break;
          }
        }
      }
      return risultato;
    }

  const handleGenerateParams = async () => {

    // generazione dei parametri
    console.log("## JSON di contract: " + JSON.stringify(contract)); //per avere un idea dei parametri esistenti
    window.myContract=contract; //per poter interrogare l'oggetto dalla console del browser come windows.myContract e capire come è fatto e come funziona

    let params='{';
    //Risorsa
    var k=Object.keys(contract.resource);
    for (let j in k)
    {
      if(typeof contract.resource[k[j]]!="object")
      {
        var tmp=contract.resource[k[j]];
        switch(typeof contract.resource[k[j]])
        {
          case "number":
            params=params+'"@@<resource.'+k[j].toLowerCase()+'>@@":'+tmp+',';
          break;
          case "boolean":
            params=params+'"@@<resource.'+k[j].toLowerCase()+'>@@":'+tmp+',';
          break;
          default:
            params=params+'"@@<resource.'+k[j].toLowerCase()+'>@@":"'+tmp+'",';
          break;
        }
      }
    }
    //Contratto
    k=Object.keys(contract);
    for (let j in k)
    {
      console.log(k[j]+":"+typeof contract[k[j]])
      if(typeof contract[k[j]]!="object")
      {
        var tmp=contract[k[j]];
        switch(typeof contract[k[j]])
        {
          case "number":
            params=params+'"@@<contract.'+k[j].toLowerCase()+'>@@":'+tmp+',';
            if(k[j].toLowerCase()==="totalcost" || k[j].toLowerCase()==="unitarycost")
              {
                  var ipart=convNumLett(parseInt(tmp).toString(),false);
                  var dpart=(tmp % 1).toFixed(2).substring(2);
                  if(k[j].toLowerCase()==="totalcost"){
                  params=params+'"@@<field.lettercost>@@":"'+ipart+'",';
                  params=params+'"@@<field.letterdecimal>@@":"'+dpart+'",';
                  }
                  else{
                      params=params+'"@@<field.letterunitarycost>@@":"'+ipart+'",';
                  params=params+'"@@<field.letterunitarydecimal>@@":"'+dpart+'",';
                  }
              }
          break;
          case "boolean":
            params=params+'"@@<contract.'+k[j].toLowerCase()+'>@@":'+tmp+',';
          break;
          default:
            params=params+'"@@<contract.'+k[j].toLowerCase()+'>@@":"'+tmp+'",';
          break;
        }
        
      }
    }
    //Tipo Contratto
    k=Object.keys(contract.contractType);
    for (let j in k)
    {
      if(typeof contract.contractType[k[j]]!="object")
      {
        var tmp=contract.contractType[k[j]];
        switch(typeof contract.contractType[k[j]])
        {
          case "number":
            params=params+'"@@<contracttype.'+k[j].toLowerCase()+'>@@":'+tmp+',';
          break;
          case "boolean":
            params=params+'"@@<contracttype.'+k[j].toLowerCase()+'>@@":'+tmp+',';
          break;
          default:
            params=params+'"@@<contracttype.'+k[j].toLowerCase()+'>@@":"'+tmp+'",';
          break;
        }
      }
    }

    //Progetto
    k=Object.keys(contract.project);
    for (let j in k)
    {
      if(typeof contract.project[k[j]]!="object")
      {
        var tmp=contract.project[k[j]];
        switch(typeof contract.project[k[j]])
        {
          case "number":
            params=params+'"@@<project.'+k[j].toLowerCase()+'>@@":'+tmp+',';
          break;
          case "boolean":
            params=params+'"@@<project.'+k[j].toLowerCase()+'>@@":'+tmp+',';
          break;
          default:
            params=params+'"@@<project.'+k[j].toLowerCase()+'>@@":"'+tmp+'",';
          break;
        }
      }
    }

    //Continua tu con gli altri oggetti di contract

    //Figure
    k=Object.keys(contract.projectFigure.figure);
    for (let j in k)
    {
      if(typeof contract.projectFigure.figure[k[j]]!="object")
      {
        var tmp=contract.projectFigure.figure[k[j]];
        switch(typeof contract.projectFigure.figure[k[j]])
        {
          case "number":
            params=params+'"@@<figure.'+k[j].toLowerCase()+'>@@":'+tmp+',';
          break;
          case "boolean":
            params=params+'"@@<figure.'+k[j].toLowerCase()+'>@@":'+tmp+',';
          break;
          default:
            params=params+'"@@<figure.'+k[j].toLowerCase()+'>@@":"'+tmp+'",';
          break;
        }
      }
    }

    //Partner
    k=Object.keys(contract.partner.resource);
    for (let j in k)
    {
      if(typeof contract.partner.resource[k[j]]!="object")
      {
        var tmp=contract.partner.resource[k[j]];
        switch(typeof contract.partner.resource[k[j]])
        {
          case "number":
            params=params+'"@@<partner.'+k[j].toLowerCase()+'>@@":'+tmp+',';
          break;
          case "boolean":
            params=params+'"@@<partner.'+k[j].toLowerCase()+'>@@":'+tmp+',';
          break;
          default:
            params=params+'"@@<partner.'+k[j].toLowerCase()+'>@@":"'+tmp+'",';
          break;
        }
      }
    }
    //Unit
    k=Object.keys(contract.unit);
    for (let j in k)
    {
      if(typeof contract.unit[k[j]]!="object")
      {
        var tmp=contract.unit[k[j]];
        switch(typeof contract.unit[k[j]])
        {
          case "number":
            params=params+'"@@<unit.'+k[j].toLowerCase()+'>@@":'+tmp+',';
          break;
          case "boolean":
            params=params+'"@@<unit.'+k[j].toLowerCase()+'>@@":'+tmp+',';
          break;
          default:
            params=params+'"@@<unit.'+k[j].toLowerCase()+'>@@":"'+tmp+'",';
          break;
        }
      }
    }
    //Unit Duration ? non dovrebbe servire
    /*k=Object.keys(contract.duration);
    for (let j in k)
    {
      if(typeof contract.duration[k[j]]!="object")
      {
        var tmp=contract.duration[k[j]];
        params=params+'"@@<duration.'+k[j].toLowerCase()+'>@@":"'+tmp+'",';
      }
    }*/

    // concateno a params i valori dei campi acquisiti, messa notazione field per distinguerli
    // verifico che siano oggetti
    if(contract?.quantity===0 /*|| (this.checkbox1.dataValue && this.quantity.getData().dataValue<=0)*/)
      params=params+'"@@<field.printmaxdays>@@":"",';
  else
      params=params+'"@@<field.printmaxdays>@@":", per un numero massimo di '+contract.quantity+' giornate,",';       
  
  
    if(typeof place!="object") {
      params=params+'"@@<field.place'.toLowerCase()+'>@@":"'+place+'",';
    }
    if(typeof foreword!="object") {
      params=params+'"@@<field.premise'.toLowerCase()+'>@@":"'+foreword+'",';
    }
    if(typeof subject!="object") {
      params=params+'"@@<field.subject'.toLowerCase()+'>@@":"'+subject+'",';
    }
    if(typeof selectedProfile?.label!="object") {
      params=params+'"@@<field.profile'.toLowerCase()+'>@@":"'+selectedProfile?.label+'",';
    }
    if(typeof notice!="object") {
      params=params+'"@@<field.notice'.toLowerCase()+'>@@":"'+notice+'",';
    }
    if(typeof mode!="object") {
      params=params+'"@@<field.mode'.toLowerCase()+'>@@":"'+mode+'",';
    }
    if(typeof invoice!="object") {
      params=params+'"@@<field.invoiceperiod'.toLowerCase()+'>@@":"'+invoice+'",';
    }
    if(typeof payment!="object") {
    params=params+'"@@<field.paymentperiod'.toLowerCase()+'>@@":"'+payment+'",';
    }
    if(typeof selectedApproves?.label!="object") {
      params=params+'"@@<field.expenseapprover'.toLowerCase()+'>@@":"'+selectedApproves?.label+'",';
    }
    if(typeof kmReimbursement!="object") {
      params=params+'"@@<field.priceperkm'.toLowerCase()+'>@@":"'+kmReimbursement+'",';
    }
    if(typeof board!="object") {
      params=params+'"@@<field.priceperfood'.toLowerCase()+'>@@":"'+board+'",';
    }
    if(typeof docDate!="object") {
      params=params+'"@@<field.docDate'.toLowerCase()+'>@@":"'+docDate+'",';
    }

    // controllo alla chiusura di params con log
    if(params.length>1) {
      params=params.slice(0,params.length-1)+'}';
      console.log("## PARAMETRI GENERATI: ", params);
    } else {
      params="";
      console.log("## Parameti non presenti");
    }

    // DOPO: "andrebbero letti tutte le variazioni contrattuali da DB
    // relative a quel contratto per fare ulteriori operazioni"

    // ora con i dati acquisiti dovrei modificare i dati di contract?
    // contractVariation con contract è mappato ManyToOne

    try {
      await updateToken();
      if (token) {
        console.log("Selected Model="+selectedModelType.value);
        timeslotsApi.contractExport(selectedModelType.value,params,token).then(response => {
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.setAttribute('download', 'contratto_generato.docx');
          document.body.appendChild(link);
          link.click();
        });
        toast.success("OK");
        handleClose();
      }
    } catch (error) {
      console.error("Error while generate params:", error);
    }
  };

  const handleGenerateModel = async () => {

    // campi da acquisire
    const generateModel = {
      ...contract,
      model: selectedModelType.value || null,
      place: place || null,
      foreword: foreword || null,
      subject: subject || null,
      selectedProfile: selectedProfile.value || null,
      notice: notice || null,
      mode: mode || null,
      invoice: invoice || null,
      payment: payment || null,
      selectedApproves: selectedApproves.value || null,
      kmReimbursement: kmReimbursement || null,
      board: board || null,
      docDate: docDate || null,
    };

    try {
      await updateToken();
      if (token) {
        //await timeslotsApi.createGenerateModel();
        //toast.success("Generazione del modello avvenuta con successo");
        console.log("OK");
        toast.success("OK");
        handleClose();
      }
    } catch (error) {
      toast.error("Errore nella generazione del modello");
      console.error("Error while generate model:", error);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        dialogClassName="large-modal"
        scrollable
      >
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title className="green-color">
            {t("generateModel")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row mx-1">
            {/* MODEL */}
            <div className="col-8">
              <Form.Group controlId="selectedModelTypeForm">
                <Form.Label className="green-color">{t("model")}</Form.Label>
                <Select
                  classNamePrefix="custom-select"
                  placeholder={t("model")}
                  value={selectedModelType}
                  onChange={setSelectedModelType}
                  options={modelOptions}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: mainColor,
                      primary: mainColor,
                      neutral20: "#dee2e6",
                    },
                  })}
                />
              </Form.Group>
            </div>

            {/* PLACE */}
            <div className="col-4">
              <Form.Group controlId="placeForm">
                <Form.Label className="green-color">{t("place")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("place")}
                  className="w-100 custom-form-control"
                  value={place}
                  onChange={(e) => setPlace(e.target.value)}
                />
              </Form.Group>
            </div>
          </div>

          <div className="row mx-1">
            {/* PREMESSA */}
            <div className="col-6">
              <Form.Group controlId="forewordForm">
                <Form.Label className="green-color">{t("foreword")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("foreword")}
                  className="w-100 custom-form-control"
                  value={foreword}
                  onChange={(e) => setForeword(e.target.value)}
                />
              </Form.Group>
            </div>

            {/* OGGETTO */}
            <div className="col-6">
              <Form.Group controlId="objectForm">
                <Form.Label className="green-color">{t("object")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("object")}
                  className="w-100 custom-form-control"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Form.Group>
            </div>
          </div>

          <div className="row mx-1">
            {/* PROFILO */}
            <div className="col-4">
              <Form.Group controlId="selectedProfileForm">
                <Form.Label className="green-color">{t("profile")}</Form.Label>
                <Select
                  classNamePrefix="custom-select"
                  placeholder={t("profile")}
                  value={selectedProfile}
                  onChange={setSelectedProfile}
                  options={figureOptions}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: mainColor,
                      primary: mainColor,
                      neutral20: "#dee2e6",
                    },
                  })}
                />
              </Form.Group>
            </div>

            {/* PREAVVISO */}
            <div className="col-4">
              <Form.Group controlId="noticeForm">
                <Form.Label className="green-color">{t("notice")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("notice")}
                  className="custom-form-control"
                  value={notice}
                  onChange={(e) => setNotice(parseInt(e.target.value, 10))}
                />
              </Form.Group>
            </div>

            {/* USA VARIAZIONI */}
            <div className="col-4 d-flex flex-column justify-content-end">
                      <Form.Group controlId="useVariationsSwitchGroup">
                        <div className="d-flex align-items-center">
                          <Form.Label className="mb-0 mr-3">{t("useVariations") + " *"}</Form.Label>
                          <div className="d-flex align-items-center">
                            <div
                              className={`mr-2 ${
                                switches.contractVariationsActive
                                  ? "text-gray"
                                  : "text-black-semibold"
                              }`}
                            >
                              OFF
                            </div>
                            <Form.Check
                              type="switch"
                              id="selfGeneratingSwitch"
                              className="custom-switch mx-0"
                              checked={switches.contractVariationsActive}
                              onChange={() =>
                                handleSwitch("contractVariationsActive")
                              }
                            />
                            <div
                              className={`mr-2 ${
                              switches.contractVariationsActive
                                ? "text-black-semibold"
                                : "text-gray"
                              }`}
                            >
                              ON
                            </div>
                          </div>
                        </div>
                      </Form.Group>
                    </div>
                </div>

          <div className="row mx-1">
            {/* MODALITA'*/}
            <div className="col-12">
              <Form.Group controlId="modeForm">
                <Form.Label className="green-color">{t("mode")}</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  placeholder={t("mode")}
                  className="w-100 custom-form-control"
                  value={mode}
                  onChange={(e) => setMode(e.target.value)}
                />
              </Form.Group>
            </div>
          </div>

          <div className="row mx-1">
            {/* FATTURA */}
            <div className="col-4">
              <Form.Group controlId="invoiceForm">
                <Form.Label className="green-color">{t("invoice")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("invoice")}
                  className="w-100 custom-form-control"
                  value={invoice}
                  onChange={(e) => setInvoice(e.target.value)}
                />
              </Form.Group>
            </div>

            {/* PAGAMENTO */}
            <div className="col-4">
              <Form.Group controlId="paymentForm">
                <Form.Label className="green-color">{t("payment")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("payment")}
                  className="w-100 custom-form-control"
                  value={payment}
                  onChange={(e) => setPayment(e.target.value)}
                />
              </Form.Group>
            </div>

            {/* APPROVA */}
            <div className="col-4">
              <Form.Group controlId="selectedApprovesForm">
                <Form.Label className="green-color">{t("approves")}</Form.Label>
                <Select
                  classNamePrefix="custom-select"
                  placeholder={t("approves")}
                  value={selectedApproves}
                  onChange={setSelectedApproves}
                  options={approverOptions}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: mainColor,
                      primary: mainColor,
                      neutral20: "#dee2e6",
                    },
                  })}
                />
              </Form.Group>
            </div>
          </div>

          <div className="row mx-1">
            {/* RIMBORSO KM */}
            <div className="col-4">
              <Form.Group controlId="kmReimbursementForm">
                <Form.Label className="green-color">
                  {t("kmReimbursement")}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("kmReimbursement")}
                  className="custom-form-control"
                  value={kmReimbursement}
                  onChange={(e) =>
                    setKmReimbursement(parseFloat(e.target.value))
                  }
                />
              </Form.Group>
            </div>

            {/* VITTO */}
            <div className="col-4">
              <Form.Group controlId="boardForm">
                <Form.Label className="green-color">{t("board")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("board")}
                  className="custom-form-control"
                  value={board}
                  onChange={(e) => setBoard(parseFloat(e.target.value))}
                />
              </Form.Group>
            </div>

            {/* DOC DATE */}
            <div className="col-4">
              <Form.Group controlId="docDateForm">
                <Form.Label className="green-color">
                  {t("docDate")}
                  <img
                    src={`${process.env.PUBLIC_URL}/calendar1.svg`}
                    alt="Calendar"
                    style={{
                      width: "1rem",
                      marginRight: "8px",
                    }}
                    className="ml-2 mb-1"
                  />
                </Form.Label>
                <div className="date-picker-wrapper w-100">
                  <DatePicker
                    selected={docDate}
                    onChange={(date) => setDocDate(date)}
                    placeholderText={t("docDate")}
                    locale={getLocale()}
                    dateFormat="dd/MM/yyyy"
                    className="form-control custom-form-control"
                  />
                </div>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="mt-2 d-flex justify-content-end align-items-center">
            <Button
              className="white-text-button fw-semibold"
              /*
                  disabled={
                    !selectedModelType ||
                    !place ||
                    !foreword ||
                    !object ||
                    !selectedProfile ||
                    !notice ||
                    !mode ||
                    !invoice ||
                    !payment ||
                    !selectedApproves ||
                    !kmReimbursement ||
                    !board ||
                    !docDate
                  }*/
              onClick={handleGenerateParams}
              //onClick={handleGenerateModel}
            >
              <img
                src={`${process.env.PUBLIC_URL}/save.svg`}
                alt="Back"
                style={{
                  width: "1rem",
                }}
                className="mr-2 mb-1"
              />
              {t("generateModel")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalGenerateModel;
