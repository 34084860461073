import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import { useAuth } from "../../AuthContext";
import { timeslotsApi } from "../../misc/TimeslotsApi";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import AltchaComponent from "./AltchaComponent";
import Loading from "./Loading";
import { config } from "../../Constants";

function ModalImportContract({ show, handleClose }) {
  const { token, updateToken } = useAuth();
  const { t } = useTranslation();
  const rootStyles = getComputedStyle(document.documentElement);
  const mainColor = rootStyles.getPropertyValue("--main-color").trim();
  const challengeAltchaUrl =  config.url.API_BASE_URL+"/api/altcha/altcha";
  const [isAltchaVerified, setIsAltchaVerified] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [parsedData, setParsedData] = useState([]);
  const formWizardRef = useRef(null);
  const [preflightResult, setPreflightResult] = useState(null);
  const [isPreflightSuccessful, setIsPreflightSuccessful] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [isPreflightLoading, setIsPreflightLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  

  useEffect(() => {
    if (!show) {
      resetWizard();
    }
  }, [show]);
  
  const nextTab = async () => {
    if (!formWizardRef.current) return;
    if (currentTabIndex === 1) { 
      await handlePreflightCheck();
    }
    formWizardRef.current.nextTab();
    setCurrentTabIndex((prevIndex) => prevIndex + 1);
  };
  
  const backTab = () => {
    if (currentTabIndex > 0) {
      formWizardRef.current.prevTab();
      setCurrentTabIndex((prevIndex) => Math.max(0, prevIndex - 1));
    }
  };

  const resetWizard = () => {
    setFileData(null);
    setParsedData([]);
    setPreflightResult(null);
    setIsPreflightSuccessful(false);
    setCurrentTabIndex(0);
    setIsPreflightLoading(false);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
  
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
  
      const headersRange = XLSX.utils.sheet_to_json(sheet, { header: 1, range: "A2:AN2" });
      if (headersRange.length === 0) {
        setParsedData([]);
        return;
      }
  
      const headers = [];
      const headerCount = {};
  
      headersRange[0].forEach((header) => {
        if (headerCount[header]) {
          headerCount[header]++;
          headers.push(`${header}_${headerCount[header]}`);
        } else {
          headerCount[header] = 1;
          headers.push(header);
        }
      });
  
      const dataRows = XLSX.utils.sheet_to_json(sheet, { header: 1, range: "A3:AN1000" });
  
      const jsonData = dataRows
        .filter((row) => row[0] !== undefined && row[0] !== null && row[0] !== "")
        .map((row, rowIndex) =>
        Object.fromEntries(
          headers.map((key, colIndex) => {
            let value = row[colIndex] !== undefined ? row[colIndex] : "";
            const getExcelColumn = (index) => {
              let column = "";
              while (index >= 0) {
                column = String.fromCharCode((index % 26) + 65) + column;
                index = Math.floor(index / 26) - 1;
              }
              return column;
            };
            let cellAddress = `${getExcelColumn(colIndex)}${rowIndex + 3}`;
  
            if (sheet[cellAddress] && sheet[cellAddress].t === "n" && sheet[cellAddress].w?.includes("/")) {
              const dateObj = XLSX.SSF.parse_date_code(sheet[cellAddress].v);
              if (dateObj) {
                value = `${String(dateObj.d).padStart(2, "0")}/${String(dateObj.m).padStart(2, "0")}/${dateObj.y}`;
              }
            }
  
            return [key, value];
          })
        )
      );
  
      setParsedData(jsonData);
    };
    reader.readAsArrayBuffer(file);
    setFileData(file);
  };

  const handlePreflightCheck = async () => {
    if (!fileData) return;
    setIsPreflightLoading(true);
    await updateToken();
    if (token) {
      try {
        const response = await timeslotsApi.contractImportPreflight(fileData, token);
        setPreflightResult(response.data);
        setIsPreflightSuccessful(response.data === "OK");
      } catch (error) {
        setIsPreflightSuccessful(false);
        setPreflightResult(error?.data || "Errore sconosciuto");
      }
    }
    setIsPreflightLoading(false);
  };

  const handleComplete = async () => {
    resetWizard();
    await updateToken();
    if (token) {
      try {
        setLoading(true);
        await timeslotsApi.contractImport(fileData, token);
        toast.success(t("contractSuccessfullyImported"));
      } catch (error) {
        toast.error(t("contractImportedError"));
        setLoading(false);
      }
    }
    setLoading(false);
    handleClose();
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (     
        <Modal
          show={show}
          onHide={handleClose}
          centered
          dialogClassName="large-modal"
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title className="green-color">
              {t("contractImport")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormWizard
              ref={formWizardRef}
              color={mainColor}
              stepSize="xs"
              onComplete={handleComplete}
              nextButtonTemplate={() => (
                <Button
                  className="green-text-button fw-semibold border-none"
                  onClick={nextTab}
                >
                  {t("next")}
                  <img
                    src={`${process.env.PUBLIC_URL}/next.svg`}
                    alt="Next"
                    style={{
                      width: "1rem",
                    }}
                    className="ml-2 mb-1"
                  />
                </Button>
              )}
              backButtonTemplate={() => (
                <Button
                  className="green-text-button fw-semibold mr-2 border-none"
                  onClick={backTab}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/back.svg`}
                    alt="Back"
                    style={{
                      width: "1rem",
                    }}
                    className="mr-2 mb-1"
                  />
                  {t("back")}
                </Button>
              )}
              finishButtonTemplate={(handleComplete) => (
                <Button
                  className="white-text-button fw-semibold mr-2"
                  onClick={handleComplete}
                  disabled={!isPreflightSuccessful || !isAltchaVerified}
                >
                  {t("importFile")}
                </Button>          
              )}
            >
              <FormWizard.TabContent title={t("chooseFile")} icon=" ">
                <div className="row text-center">
                  <div className="col-2">
                  </div>
                  <div className="col-8">
                    <Form.Group>
                      <Form.Control className="custom-form-control" type="file" accept=".xls,.xlsx" onChange={handleFileUpload} />
                    </Form.Group>
                  </div>
                  <div className="col-2">
                  </div>
                </div>
              </FormWizard.TabContent>
              <FormWizard.TabContent title={t("checkData")} icon=" ">
                <div className="row text-center">
                  <div className="col-12">
                    {parsedData.length > 0 ? (
                      <Table striped bordered hover responsive>
                        <thead>
                          <tr>
                            {Object.keys(parsedData[0]).map((key) => (
                              <th key={key}>{key}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {parsedData.slice(0, 10).map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              {Object.values(row).map((cell, cellIndex) => (
                                <td key={cellIndex}>{cell}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <p className="text-center">{t("noFileImported")}</p>
                    )}
                    {isPreflightLoading && (
                      <p className="text-center mt-5">{t("checkingFile")}...</p>
                    )}
                  </div>
                </div>
              </FormWizard.TabContent>
              <FormWizard.TabContent title={t("import")} icon=" ">
                <div className="row">
                  <div className="col-12 d-flex justify-content-center text-center">
                    <AltchaComponent challengeUrl={challengeAltchaUrl} onVerified={setIsAltchaVerified}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-flex justify-content-center text-center">
                    {preflightResult && (
                      <div className={`alert ${isPreflightSuccessful ? "alert-success" : "alert-warning"}`}>
                        {preflightResult}
                      </div>
                    )}
                  </div>
                </div>
              </FormWizard.TabContent>
            </FormWizard>
          </Modal.Body>
        </Modal>      
      )}
    </>
  );
}

export default ModalImportContract;
