import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import { useAuth } from "../../AuthContext";
import { timeslotsApi } from "../../misc/TimeslotsApi";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { enUS, it } from "date-fns/locale";
import AltchaComponent from "./AltchaComponent";
import ModalCreateProjectFigure from "./ModalCreateProjectFigure";
import { config } from "../../Constants";

function ModalAddEditContract({ show, handleClose, contract, isEditing }) {
  const { i18n, t } = useTranslation();
  const { token, updateToken } = useAuth();
  const [resources, setResources] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [selectedContractType, setSelectedContractType] = useState(null);
  const [selectedResource, setSelectedResource] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [quantity, setQuantity] = useState("");
  const [unitValue, setUnitValue] = useState("");
  const [totalValue, setTotalValue] = useState("");
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedProjectFigure, setSelectedProjectFigure] = useState(null);
  const [mainMaster, setMainMaster] = useState("");
  const [secondaryMaster, setSecondaryMaster] = useState("");
  const [percentageSecondaryMaster, setPercentageSecondaryMaster] = useState("");
  const [selectedDurationType, setSelectedDurationType] = useState(null);
  const [description, setDescription] = useState("");
  const [selectedPaymentPeriodicity, setSelectedPaymentPeriodicity] = useState(null);
  const [maximumMonthlyReporting, setMaximumMonthlyReporting] = useState("");
  const [switches, setSwitches] = useState({
      systemTimesheetActive: false,
      flatRateActive: false,
      selfGeneratingActive: false
    });
  const rootStyles = getComputedStyle(document.documentElement);
  const mainColor = rootStyles.getPropertyValue("--main-color").trim();
  const [contractActivationDate, setContractActivationDate] = useState(null);
  const [contractActive, setContractActive] = useState(false);
  const [contractClosureDate, setContractClosureDate] = useState(null);
  const [contractClosed, setContractClosed] = useState(false);
  const [originalSubmissionDate, setOriginalSubmissionDate] = useState(null);
  const [originalSubmitted, setOriginalSubmitted] = useState(false);
  const [originalReceiptDate, setOriginalReceiptDate] = useState(null);
  const [originalReceived, setOriginalReceived] = useState(false);
  const [privacy, setPrivacy] = useState("");
  const challengeAltchaUrl = config.url.API_BASE_URL+"/api/altcha/altcha";
  const [isAltchaVerified, setIsAltchaVerified] = useState(false);
  const [units, setUnits] = useState([]);
  const paymentOptions = useMemo(() => [
    { value: "monthly", label: t("monthly") },
    { value: "bimonthly", label: t("bimonthly") },
    { value: "quarterly", label: t("quarterly") },
    { value: "fourMonthly", label: t("fourMonthly") },
    { value: "singlePayment", label: t("singlePayment") },
  ], [t]);  
  const [showModalCreateProjectFigure, setShowModalCreateProjectFigure] = useState(false);
  const closeModalCreateProjectFigure = () => setShowModalCreateProjectFigure(false);
  //const [newProjectFigure, setNewProjectFigure] = useState("");
  const createProjectFigureOption = { label: "+ " + t("createProjectFigure"), value: "createProjectFigure" };
  const [projectFigures, setProjectFigures] = useState([]);
  const projectFigureOptions = [...projectFigures, createProjectFigureOption];
  const [contractTypes, setContractTypes] = useState([]);

  const getLocale = () => {
    switch (i18n.language) {
      case "it":
        return it;
      default:
        return enUS;
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      await updateToken();
      if (token) {
        try {
          const [resourcesRes, jobsRes, unitsRes, contractTypesRes] = await Promise.all([
            timeslotsApi.getAllResources(token, false),
            timeslotsApi.getProjectsByDepartment(token),
            timeslotsApi.getAllUnits(token),
            timeslotsApi.getAllContractTypes(token)
          ]);
  
          const formattedResources = resourcesRes.data.map((resource) => ({
            value: resource.id,
            label: resource.company
              ? resource.companyName
              : `${resource.name} ${resource.surname}`,
          }));
          setResources(formattedResources);
  
          const formattedProjects = jobsRes.data.map((project) => ({
            value: project.id,
            label: `${project.internalCode} - ${project.name}`,
          }));
          setJobs(formattedProjects);
  
          const formattedUnits = unitsRes.data.map((unit) => ({
            value: unit.id,
            label: unit.name
          }));
          setUnits(formattedUnits);
  
          const formattedContractTypes = contractTypesRes.data.map((contractType) => ({
            value: contractType.id,
            label: contractType.name
          }));
          setContractTypes(formattedContractTypes);
        } catch (error) {
          console.error("Error while loading initial data:", error);
        }
      }
    };
  
    fetchAllData();
  }, [token, updateToken]);

  useEffect(() => {
    const fetchPartners = async () => {
      if (token && selectedJob) {
        await updateToken();
        try {
          const response = await timeslotsApi.getPartnersByProject(selectedJob.value, token);
          const partnersData = response.data || [];
          if (partnersData.length > 0) {
            const partners = partnersData.map((partner) => ({
              value: partner.id,
              label: partner.resource.company
                ? partner.resource.companyName
                : `${partner.resource.name} ${partner.resource.surname}`,
            }));
            setPartners(partners);
          } else {
            setPartners([]);
          }
        } catch (error) {
          console.error("Error while loading partners:", error);
          setPartners([]);
        }
      } else {
        setPartners([]);
      }
    };
  
    fetchPartners();
  }, [token, updateToken, selectedJob]);

  useEffect(() => {
    const fetchProjectFigures = async () => {
      if (token && selectedJob) {
        await updateToken();
        try {
          const response = await timeslotsApi.getProjectFiguresByResourceAndProject(selectedResource.value, selectedJob.value, token);
          const projectFiguresData = response.data || [];
          if (projectFiguresData.length > 0) {
            const projectFigures = projectFiguresData.map((projectFigure) => ({
              value: projectFigure.id,
              label: projectFigure.resource.company
                ? projectFigure.resource.companyName
                : `${projectFigure.resource.name} ${projectFigure.resource.surname}`,
            }));
            setProjectFigures(projectFigures);
          } else {
            setProjectFigures([]);
          }
        } catch (error) {
          console.error("Error while loading project figures:", error);
          setProjectFigures([]);
        }
      } else {
        setProjectFigures([]);
      }
    };
  
    fetchProjectFigures();
  }, [token, updateToken, selectedResource, selectedJob]);

  useEffect(() => {
    if (
      isEditing &&
      contract && 
      resources.length > 0 && 
      jobs.length > 0 && 
      units.length > 0 && 
      contractTypes.length > 0
    ) {
      setSelectedContractType(contractTypes.find(type => type.value === contract.contractType.id) || null);
      setSelectedResource(resources.find(resource => resource.value === contract.resource.id) || null);
      setSelectedJob(jobs.find(job => job.value === contract.project.id) || null);
      setStartDate(contract.startDate);
      setEndDate(contract.endDate);
      setSelectedUnit(units.find(unit => unit.value === contract.unit.id) || null);
      setQuantity(contract.quantity);
      setUnitValue(contract.unitaryCost);
      setTotalValue(contract.totalCost);
      setSelectedPartner(contract.partner);
      setSelectedProjectFigure(contract.projectFigure);
      setMainMaster(contract.ledgerAccount);
      setSecondaryMaster(contract.secondaryLedgerAccount);
      setPercentageSecondaryMaster(contract.secondaryLedgerPercent);
      setSelectedDurationType(units.find(unit => unit.value === contract.duration.id) || null);
      setDescription(contract.description);
      setSelectedPaymentPeriodicity(paymentOptions.find(paymentOption => paymentOption.value === contract.periodicity) || null);
      setMaximumMonthlyReporting(contract.tslimit);
      setPrivacy(contract.privacyGroup);
      setSwitches({
        systemTimesheetActive: contract.tssystem || false,
        flatRateActive: contract.flat || false,
        selfGeneratingActive: contract.autogeneration || false,
      });
      setContractActive(contract.active);
      setContractActivationDate(contract.activationDate);
      setContractClosed(contract.closed);
      setContractClosureDate(contract.closingDate);
      setOriginalSubmitted(contract.originalSent);
      setOriginalSubmissionDate(contract.originalSentDate);
      setOriginalReceived(contract.original);
      setOriginalReceiptDate(contract.originalDate);
    }
  }, [isEditing, contract, contractTypes, resources, jobs, units, paymentOptions]);  

  useEffect(() => {
    if (isEditing && contract && partners.length > 0) {
      setSelectedPartner(partners.find(partner => partner.value === contract.partner?.id) || null);
    }
  }, [isEditing, contract, partners]);

  const resetModal = useCallback(() => {
      setSelectedContractType(null);
      setSelectedResource(null);
      setSelectedJob(null);
      setStartDate(null);
      setEndDate(null);
      setSelectedUnit(null);
      setQuantity(null);
      setUnitValue(null);
      setTotalValue(null);
      setSelectedPartner(null);
      setSelectedProjectFigure(null);
      setMainMaster("");
      setSecondaryMaster("");
      setPercentageSecondaryMaster(null);
      setSelectedDurationType(null);
      setDescription("");
      setSelectedPaymentPeriodicity(null);
      setMaximumMonthlyReporting(null);
      setPrivacy("");
      setSwitches({
        systemTimesheetActive: false,
        flatRateActive: false,
        selfGeneratingActive: false,
      });
      setContractActive(false);
      setContractActivationDate(null);
      setContractClosed(false);
      setContractClosureDate(null);
      setOriginalSubmitted(false);
      setOriginalSubmissionDate(null);
      setOriginalReceived(false);
      setOriginalReceiptDate(null);

    }, [
  ]);
  
  useEffect(() => {
    if (!show) {
      resetModal();
    }
  }, [show, resetModal]);

  const handleSwitch = (field) => {
    setSwitches((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleQuantityChange = (e) => {
    const newQuantity = e.target.value;
    setQuantity(newQuantity);
    if (!isNaN(newQuantity) && !isNaN(unitValue) && newQuantity !== "" && unitValue !== "") {
      setTotalValue((parseFloat(newQuantity) * parseFloat(unitValue)).toFixed(2));
    }
  };
  
  const handleUnitValueChange = (e) => {
    const newUnitValue = e.target.value;
    setUnitValue(newUnitValue);
    if (!isNaN(newUnitValue) && !isNaN(quantity) && newUnitValue !== "" && quantity !== "") {
      setTotalValue((parseFloat(quantity) * parseFloat(newUnitValue)).toFixed(2));
    }
  };

  const handleProjectFigureChange = (selected) => {
    if (selected?.value === "createProjectFigure") {
      setShowModalCreateProjectFigure(true); 
    } else {
      setSelectedProjectFigure(selected); 
    }
  };

  //Questa funzione inutilizzata serve per aggiornare, dopo la creazione della project figure, l'elenco delle figure nella modale della creazione/aggiunta contratto, va inclusa nel frontend
  /*const handleSaveProjectFigure = () => {
    if (newProjectFigure.trim() !== "") {
      const newOption = { label: newProjectFigure, value: newProjectFigure.toLowerCase().replace(/\s+/g, "_") };
      setSelectedProjectFigure([...selectedProjectFigure, newOption]); 
      projectFigures.push(newOption); 
      setNewProjectFigure(""); 
      setShowModalCreateProjectFigure(false); 
    }
  };*/

  const handleSaveContract = async () => {
    if (!contract || !contract.id) {
      toast.error(t('contractError'));
      return;
    }
  
    const updatedContract = {
      ...contract,
      contractType: selectedContractType ? {"id": selectedContractType.value} : null,
      resource: selectedResource ? {"id": selectedResource.value} : null,
      project: selectedJob ? {"id": selectedJob.value} : null,
      startDate: startDate || null,
      endDate: endDate || null,
      unit: selectedUnit ? {"id": selectedUnit.value} : null,
      quantity: quantity || 0,
      unitaryCost: unitValue || 0,
      totalCost: totalValue || 0,
      partner: selectedPartner ? {"id": selectedPartner.value} : null,
      projectFigure: selectedProjectFigure ? {"id": selectedProjectFigure.value} : null,
      ledgerAccount: mainMaster || "",
      secondaryLedgerAccount: secondaryMaster || "",
      secondaryLedgerPercent: percentageSecondaryMaster || 0,
      duration: selectedDurationType ? {"id": selectedDurationType.value} : null,
      description: description || "",
      periodicity: selectedPaymentPeriodicity ? selectedPaymentPeriodicity.label : null,
      tslimit: maximumMonthlyReporting || 0,
      tssystem: switches.systemTimesheetActive,
      flat: switches.flatRateActive,
      autogeneration: switches.selfGeneratingActive,
      active: contractActive,
      activationDate: contractActivationDate || null,
      closed: contractClosed,
      closingDate: contractClosureDate || null,
      original: originalSubmitted,
      originalDate: originalSubmissionDate || null,
      originalSent: originalReceived,
      originalSentDate: originalReceiptDate || null,
      privacyGroup: privacy || "",
    };
    
    try {
      await updateToken();
      if (token) {
        await timeslotsApi.updateContract(contract.id, updatedContract, token);
        toast.success(t('contractSuccessfullySaved'));
        handleClose(); 
      }
    } catch (error) {
      toast.error(t('contractError'));
      console.error("Error while updating contract:", error);
    }
  };

  const handleCreateContract = async () => {

    const contract = {
      contractType: selectedContractType ? {"id": selectedContractType.value} : null,
      resource: selectedResource ? {"id": selectedResource.value} : null,
      project: selectedJob ? {"id": selectedJob.value} : null,
      startDate: startDate || null,
      endDate: endDate || null,
      unit: selectedUnit ? {"id": selectedUnit.value} : null,
      quantity: quantity || 0,
      unitaryCost: unitValue || 0,
      totalCost: totalValue || 0,
      partner: selectedPartner ? {"id": selectedPartner.value} : null,
      projectFigure: selectedProjectFigure ? {"id": selectedProjectFigure.value} : null,
      ledgerAccount: mainMaster || "",
      secondaryLedgerAccount: secondaryMaster || "",
      secondaryLedgerPercent: percentageSecondaryMaster || 0,
      duration: selectedDurationType ? {"id": selectedDurationType.value} : null,
      description: description || "",
      periodicity: selectedPaymentPeriodicity ? selectedPaymentPeriodicity.label : null,
      tslimit: maximumMonthlyReporting || 0,
      tssystem: switches.systemTimesheetActive,
      flat: switches.flatRateActive,
      autogeneration: switches.selfGeneratingActive,
      active: contractActive,
      activationDate: contractActivationDate || null,
      closed: contractClosed,
      closingDate: contractClosureDate || null,
      original: originalSubmitted,
      originalDate: originalSubmissionDate || null,
      originalSent: originalReceived,
      originalSentDate: originalReceiptDate || null,
      privacyGroup: privacy || "",
    };
    
    try {
      await updateToken();
      if (token) {
        await timeslotsApi.createContract(contract, token);
        toast.success(t('contractSuccessfullySaved'));
        handleClose(); 
      }
    } catch (error) {
      toast.error(t('contractError'));
      console.error("Error while creating contract:", error);
    }
  };
  
  const customProjectFiguresStyles = {
    option: (styles, { data }) => ({
      ...styles,
      backgroundColor: data.value === "createProjectFigure" ? "#F1F8FA" : styles.backgroundColor,
    }),
  };

  return (
    <>
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="large-modal"
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title className="green-color">
          {t("addEditContracts")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="row mx-1">
        <div className="col-2">
          <Form.Group controlId="contractTypeForm">
            <Form.Label className="green-color">{t("contractType") + " *"}</Form.Label>
            <Select
              classNamePrefix="custom-select"
              placeholder={t("contractType")}
              value={selectedContractType}
              onChange={setSelectedContractType}
              options={contractTypes}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: mainColor,
                  primary: mainColor,
                  neutral20: "#dee2e6",
                },
              })}
            />
          </Form.Group>
        </div>
        <div className="col-3">
          <Form.Group controlId="resourceForm">
            <Form.Label className="green-color">{t("resource") + " *"}</Form.Label>
            <Select
              classNamePrefix="custom-select"
              placeholder={t("resource")}
              value={selectedResource}
              onChange={setSelectedResource}
              options={resources}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: mainColor,
                  primary: mainColor,
                  neutral20: "#dee2e6",
                },
              })}
            />
          </Form.Group>
        </div>
        <div className="col-3">
          <Form.Group controlId="jobForm">
            <Form.Label className="green-color">{t("job") + " *"}</Form.Label>
            <Select
              classNamePrefix="custom-select"
              placeholder={t("job")}
              value={selectedJob}
              onChange={setSelectedJob}
              options={jobs}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: mainColor,
                  primary: mainColor,
                  neutral20: "#dee2e6",
                },
              })}
            />
          </Form.Group>
        </div>
        <div className="col-2">
          <Form.Group controlId="startDate">   
            <Form.Label className="green-color">{t("startDate") + " *"}</Form.Label>   
            <div className="row">
              <div className="col-10 pt-0">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText={t("startDate")}
                  locale={getLocale()}
                  dateFormat="dd/MM/yyyy"
                  className="form-control custom-form-control"
                />  
              </div>  
              <div className="col-2 pl-0 pt-0">
                <img
                  src={`${process.env.PUBLIC_URL}/calendar1.svg`}
                  alt="Calendar"
                  style={{
                    width: "1.5rem",
                  }}
                  className="pt-2"
                />
              </div>     
            </div>       
          </Form.Group>
        </div>
        <div className="col-2">
          <Form.Group controlId="endDate">   
            <Form.Label className="green-color">{t("endDate")}</Form.Label>   
            <div className="row">
              <div className="col-10 pt-0">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  placeholderText={t("endDate")}
                  locale={getLocale()}
                  dateFormat="dd/MM/yyyy"
                  className="form-control custom-form-control"
                />  
              </div>  
              <div className="col-2 pl-0 pt-0">
                <img
                  src={`${process.env.PUBLIC_URL}/calendar1.svg`}
                  alt="Calendar"
                  style={{
                    width: "1.5rem",
                  }}
                  className="pt-2"
                />
              </div>     
            </div>       
          </Form.Group>
        </div>
      </div>
      <div className="row mx-1">
        <div className="col-2">
          <Form.Group controlId="unitForm">
            <Form.Label className="green-color">{t("unit") + " *"}</Form.Label>
            <Select
              classNamePrefix="custom-select"
              placeholder={t("unit")}
              value={selectedUnit}
              onChange={setSelectedUnit}
              options={units}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: mainColor,
                  primary: mainColor,
                  neutral20: "#dee2e6",
                },
              })}
            />
          </Form.Group>
        </div>
        <div className="col-2">
          <Form.Group controlId="quantityForm">
            <Form.Label className="green-color">{t("quantity")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("quantity")}
              className="w-100 custom-form-control"
              value={quantity}
              onChange={handleQuantityChange}
            />
          </Form.Group>
        </div>
        <div className="col-2">
          <Form.Group controlId="unitValueForm">
            <Form.Label className="green-color">{t("unitValue")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("unitValue")}
              className="w-100 custom-form-control"
              value={unitValue}
              onChange={handleUnitValueChange}
            />
          </Form.Group>
        </div>
        <div className="col-2">
          <Form.Group controlId="totalValueForm">
            <Form.Label className="green-color">{t("totalValue")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("totalValue")}
              className="w-100 custom-form-control"
              value={totalValue}
              onChange={(e) => setTotalValue(e.target.value)}
            />
          </Form.Group>
        </div>
        <div className="col-2">
          <Form.Group controlId="partnersForm">
            <Form.Label className="green-color">
              {t("partner") + " *"}
            </Form.Label>
            <Select
              classNamePrefix="custom-select"
              placeholder={t("partnerPlaceholder")}
              value={selectedPartner}
              onChange={setSelectedPartner}
              options={partners}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: mainColor,
                  primary: mainColor,
                  neutral20: "#dee2e6",
                },
              })}
            />
          </Form.Group>
        </div>
        <div className="col-2">
          <Form.Group controlId="figureForm">
            <Form.Label className="green-color">
              {t("figure") + " *"}
            </Form.Label>
            <Select
              classNamePrefix="custom-select"
              placeholder={t("figurePlaceholder")}
              value={selectedProjectFigure}
              onChange={handleProjectFigureChange}
              options={projectFigureOptions}
              styles={customProjectFiguresStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: mainColor,
                  primary: mainColor,
                  neutral20: "#dee2e6",
                },
              })}
            />
          </Form.Group>
        </div>
      </div>
      <div className="row mx-1">
        <div className="col-3">
          <Form.Group controlId="mainMasterForm">
            <Form.Label className="green-color">{t("mainMaster")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("mainMaster")}
              className="w-100 custom-form-control"
              value={mainMaster}
              onChange={(e) => setMainMaster(e.target.value)}
            />
          </Form.Group>
        </div>
        <div className="col-3">
          <Form.Group controlId="secondaryMasterForm">
            <Form.Label className="green-color">{t("secondaryMaster")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("secondaryMaster")}
              className="w-100 custom-form-control"
              value={secondaryMaster}
              onChange={(e) => setSecondaryMaster(e.target.value)}
            />
          </Form.Group>
        </div>
        <div className="col-3">
          <Form.Group controlId="percentageSecondaryMasterForm">
            <Form.Label className="green-color">{t("percentageSecondaryMaster")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("percentageSecondaryMaster")}
              className="w-100 custom-form-control"
              value={percentageSecondaryMaster}
              onChange={(e) => setPercentageSecondaryMaster(e.target.value)}
            />
          </Form.Group>
        </div>
        <div className="col-3">
          <Form.Group controlId="durationTypeForm">
            <Form.Label className="green-color">
              {t("durationType")}
            </Form.Label>
            <Select
              classNamePrefix="custom-select"
              placeholder={t("durationTypePlaceholder")}
              value={selectedDurationType}
              onChange={setSelectedDurationType}
              options={units}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: mainColor,
                  primary: mainColor,
                  neutral20: "#dee2e6",
                },
              })}
            />
          </Form.Group>
        </div>
      </div>
      <div className="row mx-1">
        <div className="col-3">
          <Form.Group controlId="descriptionForm">
            <Form.Label className="green-color">{t("description")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("description")}
              className="w-100 custom-form-control"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
        </div>
        <div className="col-3">
          <Form.Group controlId="paymentPeriodicityForm">
            <Form.Label className="green-color">
              {t("paymentPeriodicity")}
            </Form.Label>
            <Select
              classNamePrefix="custom-select"
              placeholder={t("paymentPeriodicityPlaceholder")}
              value={selectedPaymentPeriodicity}
              onChange={setSelectedPaymentPeriodicity}
              options={paymentOptions}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: mainColor,
                  primary: mainColor,
                  neutral20: "#dee2e6",
                },
              })}
            />
          </Form.Group>
        </div>
        <div className="col-3">
          <Form.Group controlId="maximumMonthlyReportingForm">
            <Form.Label className="green-color">{t("maximumMonthlyReporting")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("maximumMonthlyReporting")}
              className="w-100 custom-form-control"
              value={maximumMonthlyReporting}
              onChange={(e) => setMaximumMonthlyReporting(e.target.value)}
            />
          </Form.Group>
        </div>         
        <div className="col-3">
          <Form.Group controlId="privacy">       
            <Form.Label className="green-color">{t("privacy")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("privacy")}
              className="w-100 custom-form-control"
              value={privacy}
              onChange={(e) => setPrivacy(e.target.value)}
            />     
          </Form.Group>
        </div>     
      </div>
      <div className="row mt-2 justify-content-between">
        <div className="col-4 d-flex justify-content-center">
          <Form.Group controlId="systemTimesheetSwitchGroup">
            <div className="d-flex align-items-center">
              <Form.Label className="mb-0 mr-3">{t("systemTimesheet") + " *"}</Form.Label>
              <div className="d-flex align-items-center">
                <div
                  className={`mr-2 ${
                    switches.systemTimesheetActive
                      ? "text-gray"
                      : "text-black-semibold"
                  }`}
                >
                  OFF
                </div>
                <Form.Check
                  type="switch"
                  id="systemTimesheetSwitch"
                  className="custom-switch mx-0"
                  checked={switches.systemTimesheetActive}
                  onChange={() =>
                    handleSwitch("systemTimesheetActive")
                  }
                />
                <div
                  className={`mr-2 ${
                  switches.systemTimesheetActive
                    ? "text-black-semibold"
                    : "text-gray"
                  }`}
                >
                  ON
                </div>
              </div>
            </div>
          </Form.Group>
        </div>
        <div className="col-4 d-flex justify-content-center">
          <Form.Group controlId="flatRateSwitchGroup">
            <div className="d-flex align-items-center">
              <Form.Label className="mb-0 mr-3">{t("flatRate") + " *"}</Form.Label>
              <div className="d-flex align-items-center">
                <div
                  className={`mr-2 ${
                    switches.flatRateActive
                      ? "text-gray"
                      : "text-black-semibold"
                  }`}
                >
                  OFF
                </div>
                <Form.Check
                  type="switch"
                  id="flatRateSwitch"
                  className="custom-switch mx-0"
                  checked={switches.flatRateActive}
                  onChange={() =>
                    handleSwitch("flatRateActive")
                  }
                />
                <div
                  className={`mr-2 ${
                  switches.flatRateActive
                    ? "text-black-semibold"
                    : "text-gray"
                  }`}
                >
                  ON
                </div>
              </div>
            </div>
          </Form.Group>
        </div>
        <div className="col-4 d-flex justify-content-center">
          <Form.Group controlId="selfGeneratingSwitchGroup">
            <div className="d-flex align-items-center">
              <Form.Label className="mb-0 mr-3">{t("selfGenerating") + " *"}</Form.Label>
              <div className="d-flex align-items-center">
                <div
                  className={`mr-2 ${
                    switches.selfGeneratingActive
                      ? "text-gray"
                      : "text-black-semibold"
                  }`}
                >
                  OFF
                </div>
                <Form.Check
                  type="switch"
                  id="selfGeneratingSwitch"
                  className="custom-switch mx-0"
                  checked={switches.selfGeneratingActive}
                  onChange={() =>
                    handleSwitch("selfGeneratingActive")
                  }
                />
                <div
                  className={`mr-2 ${
                  switches.selfGeneratingActive
                    ? "text-black-semibold"
                    : "text-gray"
                  }`}
                >
                  ON
                </div>
              </div>
            </div>
          </Form.Group>
        </div>       
      </div>
      <div className="row mx-1 justify-content-center">
        <div className="col-3 text-center">
          <Form.Group controlId="contractActiveCheckbox" className="mb-2">
            <Form.Check
              type="checkbox"
              id="contractActiveCheckbox"
              className="custom-switch d-inline-block text-left"
              label={t("contractActive")}
              checked={contractActive}
              onChange={(e) => {
                setContractActive(e.target.checked);
                if (!e.target.checked) setContractActivationDate(null);
              }}
            />
          </Form.Group>
          <div className="d-flex justify-content-center">
            <Form.Group controlId="contractActivationDate" className="mb-0">             
              <DatePicker
                selected={contractActive ? contractActivationDate : null}
                onChange={(date) => setContractActivationDate(date)}
                placeholderText={t("contractActivationDate")}
                locale={getLocale()}
                dateFormat="dd/MM/yyyy"
                className="form-control custom-form-control w-auto"
                disabled={!contractActive}
              />             
            </Form.Group>
            <div className="pl-2 mt-1">
              <img
                src={`${process.env.PUBLIC_URL}/calendar1.svg`}
                alt="Calendar"
                style={{ width: "1.5rem"}}
              />
            </div>
          </div>
        </div>
        <div className="col-3 text-center">
          <Form.Group controlId="contractClosedCheckbox" className="mb-2">
            <Form.Check
              type="checkbox"
              id="contractClosedCheckbox"
              className="custom-switch d-inline-block text-left"
              label={t("contractClosed")}
              checked={contractClosed}
              onChange={(e) => {
                setContractClosed(e.target.checked);
                if (!e.target.checked) setContractClosureDate(null);
              }}
            />
          </Form.Group>
          <div className="d-flex justify-content-center">
            <Form.Group controlId="contractClosureDate" className="mb-0">             
              <DatePicker
                selected={contractClosed ? contractClosureDate : null}
                onChange={(date) => setContractClosureDate(date)}
                placeholderText={t("contractClosureDate")}
                locale={getLocale()}
                dateFormat="dd/MM/yyyy"
                className="form-control custom-form-control w-auto"
                disabled={!contractClosed}
              />             
            </Form.Group>
            <div className="pl-2 mt-1">
              <img
                src={`${process.env.PUBLIC_URL}/calendar1.svg`}
                alt="Calendar"
                style={{ width: "1.5rem"}}
              />
            </div>
          </div>
        </div>
        <div className="col-3 text-center">
          <Form.Group controlId="originalSubmittedCheckbox" className="mb-2">
            <Form.Check
              type="checkbox"
              id="originalSubmittedCheckbox"
              className="custom-switch d-inline-block text-left"
              label={t("originalSubmitted")}
              checked={originalSubmitted}
              onChange={(e) => {
                setOriginalSubmitted(e.target.checked);
                if (!e.target.checked) setOriginalSubmissionDate(null);
              }}
            />
          </Form.Group>
          <div className="d-flex justify-content-center">
            <Form.Group controlId="originalSubmissionDate" className="mb-0">             
              <DatePicker
                selected={originalSubmitted ? originalSubmissionDate : null}
                onChange={(date) => setOriginalSubmissionDate(date)}
                placeholderText={t("originalSubmissionDate")}
                locale={getLocale()}
                dateFormat="dd/MM/yyyy"
                className="form-control custom-form-control w-auto"
                disabled={!originalSubmitted}
              />             
            </Form.Group>
            <div className="pl-2 mt-1">
              <img
                src={`${process.env.PUBLIC_URL}/calendar1.svg`}
                alt="Calendar"
                style={{ width: "1.5rem"}}
              />
            </div>
          </div>
        </div>
        <div className="col-3 text-center">
          <Form.Group controlId="originalReceivedCheckbox" className="mb-2">
            <Form.Check
              type="checkbox"
              id="originalReceivedCheckbox"
              className="custom-switch d-inline-block text-left"
              label={t("originalReceived")}
              checked={originalReceived}
              onChange={(e) => {
                setOriginalReceived(e.target.checked);
                if (!e.target.checked) setOriginalReceiptDate(null);
              }}
            />
          </Form.Group>
          <div className="d-flex justify-content-center">
            <Form.Group controlId="originalReceiptDate" className="mb-0">             
              <DatePicker
                selected={originalReceived ? originalReceiptDate : null}
                onChange={(date) => setOriginalReceiptDate(date)}
                placeholderText={t("originalReceiptDate")}
                locale={getLocale()}
                dateFormat="dd/MM/yyyy"
                className="form-control custom-form-control w-auto"
                disabled={!originalReceived}
              />             
            </Form.Group>
            <div className="pl-2 mt-1">
              <img
                src={`${process.env.PUBLIC_URL}/calendar1.svg`}
                alt="Calendar"
                style={{ width: "1.5rem"}}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2 container d-flex justify-content-center align-items-center">
        <div className="row">
          <div className="col d-flex justify-content-center align-items-center gap-3">
            <AltchaComponent challengeUrl={challengeAltchaUrl} onVerified={setIsAltchaVerified} />
            <Button className="white-text-button fw-semibold" 
              disabled={!isAltchaVerified||
                !selectedContractType ||
                !selectedResource ||
                !selectedJob ||
                !startDate ||
                !selectedUnit ||
                !selectedPartner ||
                !selectedProjectFigure}
                onClick={isEditing ? handleSaveContract : handleCreateContract}>
              {t("save")}
            </Button>
          </div>
        </div>
      </div>
      </Modal.Body>
    </Modal> 
    <ModalCreateProjectFigure
      show={showModalCreateProjectFigure}
      handleClose={closeModalCreateProjectFigure}
    />
    </>
  );
}

export default ModalAddEditContract;
