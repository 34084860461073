import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form, ListGroup } from "react-bootstrap";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import DatePicker from "react-datepicker";
import { enUS, it } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import { useAuth } from "../../AuthContext";
import { timeslotsApi } from "../../misc/TimeslotsApi";
import { toast } from "react-toastify";
import ModalCreateResource from "./ModalCreateResource";
import AltchaComponent from "./AltchaComponent";
import { config } from "../../Constants";

function ModalCreateProject({ show, handleClose }) {
  const { token, updateToken } = useAuth();
  const { t } = useTranslation();
  const rootStyles = getComputedStyle(document.documentElement);
  const mainColor = rootStyles.getPropertyValue("--main-color").trim();
  const { i18n } = useTranslation();
  const [projectName, setProjectName] = useState("");
  const [projectCig, setProjectCig] = useState("");
  const [projectCup, setProjectCup] = useState("");
  const [projectInternalCode, setProjectInternalCode] = useState("");
  const [projectExternalCode, setProjectExternalCode] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [projectStartDate, setProjectStartDate] = useState(null);
  const [projectEndDate, setProjectEndDate] = useState(null);
  const [projectCostCenter, setProjectCostCenter] = useState("");
  const [projectValue, setProjectValue] = useState(0);
  const [projectActive, setProjectActive] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [workingDaysHours, setWorkingDaysHours] = useState(null);
  const [maximumAllowedHours, setMaximumAllowedHours] = useState(null);
  const [contractualPremises, setContractualPremises] = useState("");
  const [switches, setSwitches] = useState({
    templateFieldsActive: false,
    templateSubtaskActive: false,
    templateFieldSubtaskActive: false,
    templatePlacesActive: false,
    templateNotesActive: false,
    templateOutputActive: false,
    templateHolidaysConsentActive: false,
    taskActive: false,
    fieldActive: false,
    subtaskActive: false,
    outputActive: false,
    placeActive: false,
  });
  const [resources, setResources] = useState([]);
  const [selectedProjectPartners, setSelectedProjectPartners] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectKey, setSelectKey] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [selectedTaskIndex, setSelectedTaskIndex] = useState(null);
  const [taskName, setTaskName] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedTaskCategory, setSelectedTaskCategory] = useState(null);
  const [taskInternalCode, setTaskInternalCode] = useState("");
  const [taskExternalCode, setTaskExternalCode] = useState("");
  const [fields, setFields] = useState([]);
  const [selectedFieldIndex, setSelectedFieldIndex] = useState(null);
  const [fieldName, setFieldName] = useState("");
  const [fieldDescription, setFieldDescription] = useState("");
  const [fieldInternalCode, setFieldInternalCode] = useState("");
  const [fieldExternalCode, setFieldExternalCode] = useState("");
  const [subtasks, setSubtasks] = useState([]);
  const [selectedSubtaskIndex, setSelectedSubtaskIndex] = useState(null);
  const [subtaskName, setSubtaskName] = useState("");
  const [subtaskDescription, setSubtaskDescription] = useState("");
  const [taskOptions, setTaskOptions] = useState([]);
  const [fieldOptions, setFieldOptions] = useState([]);
  const [selectedTaskSubtask, setSelectedTaskSubtask] = useState(null);
  const [selectedFieldSubtask, setSelectedFieldSubtask] = useState(null);
  const [subtaskInternalCode, setSubtaskInternalCode] = useState("");
  const [subtaskExternalCode, setSubtaskExternalCode] = useState("");
  const [outputs, setOutputs] = useState([]);
  const [selectedOutputIndex, setSelectedOutputIndex] = useState(null);
  const [outputName, setOutputName] = useState("");
  const [outputDescription, setOutputDescription] = useState("");
  const [outputInternalCode, setOutputInternalCode] = useState("");
  const [outputExternalCode, setOutputExternalCode] = useState("");
  const [selectedTaskOutput, setSelectedTaskOutput] = useState(null);
  const [places, setPlaces] = useState([]);
  const [selectedPlaceIndex, setSelectedPlaceIndex] = useState(null);
  const [placeName, setPlaceName] = useState("");
  const [placeDescription, setPlaceDescription] = useState("");
  const [placeInternalCode, setPlaceInternalCode] = useState("");
  const [placeExternalCode, setPlaceExternalCode] = useState("");
  const [placeType, setPlaceType] = useState("");
  const formWizardRef = React.createRef();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [showModalCreateResource, setShowModalCreateResource] = useState(false);
  const [templateData, setTemplateData] = useState({
    project: null,
    tasks: [],
    fields: [],
    subtasks: [],
    outputs: [],
    places: [],
    customers: [],
    partners: [],
});
  const [savingTemplate, setSavingTemplate] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");
  const [units, setUnits] = useState([]);
  const hoursRegex = /^([0-9]{2}):([0-9]{2})$/;
  const [isWorkingDaysHoursValid, setIsWorkingDaysHoursValid] = useState(true);
  const [isMaximumAllowedHoursValid, setIsMaximumAllowedHoursValid] = useState(true);
  const challengeAltchaUrl = config.url.API_BASE_URL+"/api/altcha/altcha";
  const [isAltchaVerified, setIsAltchaVerified] = useState(false);

  const dateOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const challengeUrl = "http://localhost:8081/api/altcha/altcha";

  const findNextTab = (currentIndex) => {
    const tabSequence = [
      { index: 4, active: switches.templateFieldsActive },
      { index: 5, active: switches.templateSubtaskActive },
      { index: 6, active: switches.templateOutputActive },
      { index: 7, active: switches.templatePlacesActive },
    ];
    // Trova il primo tab attivo con indice maggiore di `currentIndex`
    for (const tab of tabSequence) {
      if (tab.index > currentIndex && tab.active) {
        return tab.index;
      }
    }
    return 8; // Se non ci sono altri tab attivi, torna a "dataConfirmation"
  };
  

  const findPreviousTab = (currentIndex) => {
    const tabSequence = [
      { index: 7, active: switches.templatePlacesActive },
      { index: 6, active: switches.templateOutputActive },
      { index: 5, active: switches.templateSubtaskActive },
      { index: 4, active: switches.templateFieldsActive },
    ];
    // Trova il primo tab attivo con indice minore di `currentIndex`
    for (const tab of tabSequence) {
      if (tab.index < currentIndex && tab.active) {
        return tab.index;
      }
    }
    return 3; // Torna a "task" se nessun tab precedente è attivo
  };
  
  const nextTab = () => {
    if (currentTabIndex < 3) {
      // Avanza normalmente fino al tab 3
      formWizardRef.current?.nextTab();
      setCurrentTabIndex((prevIndex) => prevIndex + 1);
    } else {
      // Applica il salto condizionato dal tab 3 in poi
      const nextIndex = findNextTab(currentTabIndex);
      formWizardRef.current?.goToTab(nextIndex);
      setCurrentTabIndex(nextIndex);
    }
  };
  
  const backTab = () => {
    if (currentTabIndex > 3 && currentTabIndex <= 8) {
      const previousIndex = findPreviousTab(currentTabIndex);
      formWizardRef.current?.goToTab(previousIndex);
      setCurrentTabIndex(previousIndex);
    } else {
      formWizardRef.current?.prevTab();
      setCurrentTabIndex((prevIndex) => Math.max(0, prevIndex - 1));
    }
  };
  
  useEffect(() => {
    const fetchTemplates = async () => {
      await updateToken();
      if (token) {
        timeslotsApi
          .getAllTemplates(token)
          .then((response) => {
            const formattedTemplates = response.data.map((template) => ({
              value: template.id,
              label: template.name,
            }));
            setTemplates(formattedTemplates);
          })
          .catch((error) => {
            console.error("Error while loading templates:", error);
          });
      }
    };

    fetchTemplates();
  }, [token, updateToken]);

  const fetchResources = useCallback(async () => {
    await updateToken();
    if (token) {
      timeslotsApi
        .getAllResources(token, false)
        .then((response) => {
          const formattedResources = response.data.map((resource) => ({
            value: resource.id,
            label: resource.company
              ? resource.companyName
              : `${resource.name} ${resource.surname}`,
          }));
          setResources(formattedResources);
        })
        .catch((error) => {
          console.error("Error while loading resources:", error);
        });
    }
  }, [token, updateToken]);

  useEffect(() => {
    fetchResources();
  }, [fetchResources]);

  useEffect(() => {
    const fetchCategories = async () => {
      await updateToken();
      if (token) {
        timeslotsApi
          .getAllCategories(token, false)
          .then((response) => {
            const formattedCategories = response.data.map((category) => ({
              value: category.id,
              label: category.name,
            }));
            setCategories(formattedCategories);
          })
          .catch((error) => {
            console.error("Error while loading categories:", error);
          });
      }
    };

    fetchCategories();
  }, [token, updateToken]);

  const getLocale = () => {
    switch (i18n.language) {
      case "it":
        return it;
      default:
        return enUS;
    }
  };

  const resetFormProject = useCallback(() => {
    setProjectName("");
    setProjectCig("");
    setProjectCup("");
    setProjectDescription("");
    setProjectInternalCode("");
    setProjectExternalCode("");
    setProjectStartDate(null);
    setProjectEndDate(null);
    setProjectCostCenter("");
    setProjectValue(0);
    setProjectActive(false);
  }, []);

  const handleSwitch = (field) => {
    setSwitches((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handlePlaceTypeChange = (event) => {
    setPlaceType(event.target.value);
  };

  const handleAddTask = () => {
    const newTask = {
      name: taskName,
      description: taskDescription,
      category: selectedTaskCategory,
      internalCode: taskInternalCode,
      externalKey: taskExternalCode,
      active: switches.taskActive,
      position: tasks.length,
    };
    const updatedTasks = [...tasks, newTask];
    setTasks(updatedTasks);
    setTemplateData(prevData => ({ ...prevData, tasks: updatedTasks }));
    resetFormTasks();
  };

  const handleSelectTask = (index) => {
    const task = tasks[index];
    setSelectedTaskIndex(index);
    setTaskName(task.name);
    setTaskDescription(task.description);
    setTaskInternalCode(task.internalCode);
    setTaskExternalCode(task.externalKey);
    setSelectedTaskCategory(task.category);
    setSwitches({ taskActive: task.active });
  };

  const handleEditTask = () => {
    if (selectedTaskIndex !== null) {
      const updatedTasks = [...tasks];
      updatedTasks[selectedTaskIndex] = {
        internalCode: taskInternalCode,
        name: taskName,
        description: taskDescription,
        externalKey: taskExternalCode,
        category: selectedTaskCategory,
        active: switches.taskActive,
        position: updatedTasks[selectedTaskIndex].position,
      };
      setTasks(updatedTasks);
      setTemplateData(prevData => ({ ...prevData, tasks: updatedTasks }));     
      resetFormTasks();
    }
  };

  const handleDeleteTask = () => {
    if (selectedTaskIndex !== null) {
      const updatedTasks = tasks.filter(
        (_, index) => index !== selectedTaskIndex
      ).map((task, index) => ({
        ...task,
        position: index, 
      }));
      setTasks(updatedTasks);
      setTemplateData(prevData => ({ ...prevData, tasks: updatedTasks }));
      resetFormTasks();
    }
  };

  const resetFormTasks = useCallback(() => {
    setTaskName("");
    setTaskDescription("");
    setTaskInternalCode("");
    setTaskExternalCode("");
    setSelectedTaskCategory(null);
    setSwitches({ taskActive: false });
    setSelectedTaskIndex(null);
  }, []);

  const handleAddField = () => {
    const newField = {
      name: fieldName,
      description: fieldDescription,
      internalCode: fieldInternalCode,
      externalKey: fieldExternalCode,
      active: switches.fieldActive,
      position: fields.length,
    };
    setFields([...fields, newField]);
    resetFormFields();
  };

  const handleSelectField = (index) => {
    const field = fields[index];
    setSelectedFieldIndex(index);
    setFieldName(field.name);
    setFieldDescription(field.description);
    setFieldInternalCode(field.internalCode);
    setFieldExternalCode(field.externalKey);
    setSwitches({ fieldActive: field.active });
  };

  const handleEditField = () => {
    if (selectedFieldIndex !== null) {
      const updatedFields = [...fields];
      updatedFields[selectedFieldIndex] = {
        internalCode: fieldInternalCode,
        name: fieldName,
        description: fieldDescription,
        externalKey: fieldExternalCode,
        active: switches.fieldActive,
        position: updatedFields[selectedFieldIndex].position,
      };
      setFields(updatedFields);
      resetFormFields();
    }
  };

  const handleDeleteField = () => {
    if (selectedFieldIndex !== null) {
      const updatedFields = fields.filter(
        (_, index) => index !== selectedFieldIndex
      ).map((field, index) => ({
        ...field,
        position: index, 
      }));
      setFields(updatedFields);
      resetFormFields();
    }
  };

  const resetFormFields = useCallback(() => {
    setFieldName("");
    setFieldDescription("");
    setFieldInternalCode("");
    setFieldExternalCode("");
    setSwitches({ fieldActive: false });
    setSelectedFieldIndex(null);
  }, []);

  const handleAddSubtask = () => {
    const newSubtask = {
      name: subtaskName,
      description: subtaskDescription,
      internalCode: subtaskInternalCode,
      externalKey: subtaskExternalCode,
      active: switches.subtaskActive,
      field: selectedFieldSubtask,
      task: selectedTaskSubtask,
    };
    setSubtasks([...subtasks, newSubtask]);
    resetFormSubtasks();
  };

  const handleSelectSubtask = (index) => {
    const subtask = subtasks[index];
    setSelectedSubtaskIndex(index);
    setSubtaskName(subtask.name);
    setSubtaskDescription(subtask.description);
    setSubtaskInternalCode(subtask.internalCode);
    setSubtaskExternalCode(subtask.externalKey);
    setSwitches({ subtaskActive: subtask.active });
    setSelectedTaskSubtask(subtask.task);
    setSelectedFieldSubtask(subtask.field);
  };

  const handleEditSubtask = () => {
    if (selectedSubtaskIndex !== null) {
      const updatedSubtasks = [...subtasks];
      updatedSubtasks[selectedSubtaskIndex] = {
        internalCode: subtaskInternalCode,
        name: subtaskName,
        description: subtaskDescription,
        externalKey: subtaskExternalCode,
        field: selectedFieldSubtask,
        task: selectedTaskSubtask,
        active: switches.subtaskActive,
      };
      setSubtasks(updatedSubtasks);
      resetFormSubtasks();
    }
  };

  const handleDeleteSubtask = () => {
    if (selectedSubtaskIndex !== null) {
      const updatedSubtasks = subtasks.filter(
        (_, index) => index !== selectedSubtaskIndex
      );
      setSubtasks(updatedSubtasks);
      resetFormSubtasks();
    }
  };

  const resetFormSubtasks = useCallback(() => {
    setSubtaskName("");
    setSubtaskDescription("");
    setSubtaskInternalCode("");
    setSubtaskExternalCode("");
    setSelectedFieldSubtask(null);
    setSelectedTaskSubtask(null);
    setSwitches({ subtaskActive: false });
    setSelectedSubtaskIndex(null);
  }, []);

  const formatTasksForSelect = (tasks) => {
    return tasks.map((task, index) => ({
      value: task.internalCode,
      label: `${task.internalCode} - ${task.name}`,
      originalTask: task,
    }));
  };
  
  const formatFieldsForSelect = (fields) => {
    return fields.map((field, index) => ({
      value: field.internalCode,
      label: `${field.internalCode} - ${field.name}`,
      originalField: field,
    }));
  };

  const handleAddOutput = () => {
    const newOutput = {
      name: outputName,
      description: outputDescription,
      internalCode: outputInternalCode,
      externalKey: outputExternalCode,
      active: switches.outputActive,
      task: selectedTaskOutput,
    };
    setOutputs([...outputs, newOutput]);
    resetFormOutputs();
  };

  const handleSelectOutput = (index) => {
    const output = outputs[index];
    setSelectedOutputIndex(index);
    setOutputName(output.name);
    setOutputDescription(output.description);
    setOutputInternalCode(output.internalCode);
    setOutputExternalCode(output.externalKey);
    setSwitches({ outputActive: output.active });
    setSelectedTaskOutput(output.task);
  };

  const handleEditOutput = () => {
    if (selectedOutputIndex !== null) {
      const updatedOutputs = [...outputs];
      updatedOutputs[selectedOutputIndex] = {
        internalCode: outputInternalCode,
        name: outputName,
        description: outputDescription,
        externalKey: outputExternalCode,
        task: selectedTaskOutput,
        active: switches.outputActive,
      };
      setOutputs(updatedOutputs);
      resetFormOutputs();
    }
  };

  const handleDeleteOutput = () => {
    if (selectedOutputIndex !== null) {
      const updatedOutputs = outputs.filter(
        (_, index) => index !== selectedOutputIndex
      );
      setOutputs(updatedOutputs);
      resetFormOutputs();
    }
  };

  const resetFormOutputs = useCallback(() => {
    setOutputName("");
    setOutputDescription("");
    setOutputInternalCode("");
    setOutputExternalCode("");
    setSelectedTaskOutput(null);
    setSwitches({ outputActive: false });
    setSelectedOutputIndex(null);
  }, []);

  const handleAddPlace = () => {
    const newPlace = {
      name: placeName,
      description: placeDescription,
      internalCode: placeInternalCode,
      externalKey: placeExternalCode,
      active: switches.placeActive,
      icon: placeType,
    };
    setPlaces([...places, newPlace]);
    resetFormPlaces();
  };

  const handleSelectPlace = (index) => {
    const place = places[index];
    setSelectedPlaceIndex(index);
    setPlaceName(place.name);
    setPlaceDescription(place.description);
    setPlaceInternalCode(place.internalCode);
    setPlaceExternalCode(place.externalKey);
    setPlaceType(place.icon);
    setSwitches({ placeActive: place.active });
  };

  const handleEditPlace = () => {
    if (selectedPlaceIndex !== null) {
      const updatedPlaces = [...places];
      updatedPlaces[selectedPlaceIndex] = {
        internalCode: placeInternalCode,
        name: placeName,
        description: placeDescription,
        externalKey: placeExternalCode,
        active: switches.placeActive,
        icon: placeType,
      };
      setPlaces(updatedPlaces);
      resetFormPlaces();
    }
  };

  const handleDeletePlace = () => {
    if (selectedPlaceIndex !== null) {
      const updatedPlaces = places.filter(
        (_, index) => index !== selectedPlaceIndex
      );
      setPlaces(updatedPlaces);
      resetFormPlaces();
    }
  };

  const resetFormPlaces = useCallback(() => {
    setPlaceName("");
    setPlaceDescription("");
    setPlaceInternalCode("");
    setPlaceExternalCode("");
    setSwitches({ placeActive: false });
    setPlaceType("");
    setSelectedPlaceIndex(null);
  }, []);

  useEffect(() => {
    const formattedTasks = formatTasksForSelect(tasks);
    setTaskOptions(formattedTasks);
    if (
      selectedTaskSubtask &&
      !formattedTasks.find((opt) => opt.value === selectedTaskSubtask.value)
    ) {
      setSelectedTaskSubtask(null);
    }
    const formattedFields = formatFieldsForSelect(fields);
    setFieldOptions(formattedFields);
    if (
      selectedFieldSubtask &&
      !formattedFields.find((opt) => opt.value === selectedFieldSubtask.value)
    ) {
      setSelectedFieldSubtask(null);
    }
  }, [tasks, selectedTaskSubtask, fields, selectedFieldSubtask]);

  const handleCreateProject = async () => {
    if (
      !projectName ||
      !projectInternalCode ||
      !projectExternalCode ||
      !projectActive
    )
      return;
    await updateToken();
    const project = {
      name: projectName,
      cig: projectCig,
      cup: projectCup,
      internalCode: projectInternalCode,
      externalKey: projectExternalCode,
      description: projectDescription,
      actualStartDate: projectStartDate
        .toLocaleDateString("it-IT", dateOptions)
        .split("/")
        .reverse()
        .join("-"),
      actualEndDate: projectEndDate
        .toLocaleDateString("it-IT", dateOptions)
        .split("/")
        .reverse()
        .join("-"),
      plannedStartDate: projectStartDate
        .toLocaleDateString("it-IT", dateOptions)
        .split("/")
        .reverse()
        .join("-"),
      plannedEndDate: projectEndDate
        .toLocaleDateString("it-IT", dateOptions)
        .split("/")
        .reverse()
        .join("-"),
      costCentre: projectCostCenter,
      cost: projectValue,
      active: projectActive,
    };
    try {
      const response = await timeslotsApi.createProject(project, token);
      const projectCreated = response.data;        
      return projectCreated;
    } catch (error) {
      console.error("Error creating project:", error);
      toast.error(t("createProjectError"));
      return null;
    }
  };

  useEffect(() => {
    const fetchUnits = async () => {
      await updateToken();
      if (token) {
        timeslotsApi
          .getAllUnits(token)
          .then((response) => {
            setUnits(response.data);
          })
          .catch((error) => {
            console.error("Error while loading units:", error);
          });
      }
    };

    fetchUnits();
  }, [token, updateToken]);

  const handleChangeWorkingDaysHours = (e) => {
    const value = e.target.value;
    setWorkingDaysHours(value);
    setIsWorkingDaysHoursValid(hoursRegex.test(value));
  };

  const handleChangeMaximumAllowedHours = (e) => {
    const value = e.target.value;
    setMaximumAllowedHours(value);
    setIsMaximumAllowedHoursValid(hoursRegex.test(value));
  };

  const getUnitId = async (time, token) => {
    try {
      const [hours, minutes] = time.split(":").map(Number);
      const existingUnit = units.find(unit => unit.hours === hours && unit.minutes === minutes);
      if (existingUnit) {
        return existingUnit.id;
      } else {
        const newUnit = {
          hours,
          minutes,
          days: 0,
          years: 0,
          months: 0,
          seconds: 0,
          name: "Ora limite"
        };
        const response = await timeslotsApi.createUnit(newUnit, token);
        return response.data.id; 
      }
    } catch (error) {
      console.error("Errore while loading or creating units:", error);
      return null; 
    }
  };

  const handleCreateProjectPreferences = async (project) => {
    if (!project) return;
    await updateToken();
    const projectId = {"id": project.id};
    const PREFERENCE_TIMESHEET_DAY_OF_WORK_UNITS = { id: 'TIMESHEET_DAY_OF_WORK_UNITS' };
    const PREFERENCE_TIMESHEET_DAY_OF_WORK_ERROR_UNITS = { id: 'TIMESHEET_DAY_OF_WORK_ERROR_UNITS' };
    const PREFERENCE_CONTRACT_PREMISE_TEXT = { id: 'CONTRACT_PREMISE_TEXT' };
    const PREFERENCE_SHOW_FIELDS_TIMESHEET_BOOLEAN = { id: 'SHOW_FIELDS_TIMESHEET_BOOLEAN' };
    const PREFERENCE_SHOW_SUBTASKS_TIMESHEET_BOOLEAN = { id: 'SHOW_SUBTASKS_TIMESHEET_BOOLEAN' };
    const PREFERENCE_FIELD_AS_SUBTASK_PARENT_BOOLEAN = { id: 'FIELD_AS_SUBTASK_PARENT_BOOLEAN' };
    const PREFERENCE_SHOW_PLACES_TIMESHEET_BOOLEAN = { id: 'SHOW_PLACES_TIMESHEET_BOOLEAN' };
    const PREFERENCE_SHOW_NOTE_TIMESHEET_BOOLEAN = { id: 'SHOW_NOTE_TIMESHEET_BOOLEAN' };
    const PREFERENCE_SHOW_OUTPUTS_TIMESHEET_BOOLEAN = { id: 'SHOW_OUTPUTS_TIMESHEET_BOOLEAN' };
    const PREFERENCE_NO_SELECT_HOLIDAYS_BOOLEAN = { id: 'NO_SELECT_HOLIDAYS_BOOLEAN' };

    const workingDaysHoursUnitId = await getUnitId(workingDaysHours, token);
    if (!workingDaysHoursUnitId) {
      console.error("Impossibile ottenere o creare l'unità per workingDaysHours");
      return; 
    }
    const workingDaysHoursUnitIdJson = {"id": workingDaysHoursUnitId};

    const maximumAllowedHoursUnitId = await getUnitId(maximumAllowedHours, token);
    if (!maximumAllowedHoursUnitId) {
      console.error("Impossibile ottenere o creare l'unità per maximumAllowedHours");
      return; 
    }
    const maximumAllowedHoursUnitIdJson = {"id": maximumAllowedHoursUnitId};

    const projectPreferences = [
      {
        project: projectId,
        preference: PREFERENCE_TIMESHEET_DAY_OF_WORK_UNITS,
        typeInteger: parseInt(workingDaysHours, 10),
        unit: workingDaysHoursUnitIdJson
      },
      {
        project: projectId,
        preference: PREFERENCE_TIMESHEET_DAY_OF_WORK_ERROR_UNITS,
        typeInteger: parseInt(maximumAllowedHours, 10),
        unit: maximumAllowedHoursUnitIdJson
      },
      {
        project: projectId,
        preference: PREFERENCE_CONTRACT_PREMISE_TEXT,
        typeText: contractualPremises,
      },
      {
        project: projectId,
        preference: PREFERENCE_SHOW_FIELDS_TIMESHEET_BOOLEAN,
        typeBoolean: switches.templateFieldsActive ? 1 : 0,
      },
      {
        project: projectId,
        preference: PREFERENCE_SHOW_SUBTASKS_TIMESHEET_BOOLEAN,
        typeBoolean: switches.templateSubtaskActive ? 1 : 0,
      },
      {
        project: projectId,
        preference: PREFERENCE_FIELD_AS_SUBTASK_PARENT_BOOLEAN,
        typeBoolean: switches.templateFieldSubtaskActive ? 1 : 0,
      },
      {
        project: projectId,
        preference: PREFERENCE_SHOW_PLACES_TIMESHEET_BOOLEAN,
        typeBoolean: switches.templatePlacesActive ? 1 : 0,
      },
      {
        project: projectId,
        preference: PREFERENCE_SHOW_NOTE_TIMESHEET_BOOLEAN,
        typeBoolean: switches.templateNotesActive ? 1 : 0,
      },
      {
        project: projectId,
        preference: PREFERENCE_SHOW_OUTPUTS_TIMESHEET_BOOLEAN,
        typeBoolean: switches.templateOutputActive ? 1 : 0,
      },
      {
        project: projectId,
        preference: PREFERENCE_NO_SELECT_HOLIDAYS_BOOLEAN,
        typeBoolean: switches.templateHolidaysConsentActive ? 1 : 0,
      },
    ];
    
    try {
      for (const preference of projectPreferences) {
        await timeslotsApi.createProjectPreference(preference, token);
      }
    } catch (error) {
      console.error("Error creating project preferences:", error);
    }
  };
  
  const handleCreateCustomers = async (project) => {
    if (!project || selectedCustomers.length === 0) return;
    await updateToken();
    try {
      for (const customer of selectedCustomers) {
        const resource = {"id": customer.value};
        const newCustomer = {
          project: project,
          resource: resource
        };
        await timeslotsApi.createCustomer(newCustomer, token);
      }
    } catch (error) {
      console.error("Error creating customers:", error);
    }
  };

  const handleCreateProjectPartners = async (project) => {
    if (!project || selectedProjectPartners.length === 0) return;
    await updateToken();
    try {
      for (const partner of selectedProjectPartners) {
        const resource = {"id": partner.value};
        const newPartner = {
          project: project,
          resource: resource
        };
        await timeslotsApi.createPartner(newPartner, token);
      }
    } catch (error) {
      console.error("Error creating project partners:", error);
    }
  };

  const handleCreateTasks = async (project) => {
    if (!project || tasks.length === 0) return;
    await updateToken();
    const createdTasks = [];
    try {
      for (let i = 0; i < tasks.length; i++) {
        const task = tasks[i];
        const category = { id: task.category.value };
        const newTask = {
          active: task.active,
          description: task.description,
          externalKey: task.externalKey,
          internalCode: task.internalCode,
          name: task.name,
          category: category,
          project: project,
        };
        const createdTask = await timeslotsApi.createTask(newTask, token);     
        tasks[i] = { ...task, id: createdTask.data.id };
        createdTasks.push(createdTask);
      }
    } catch (error) {
      console.error("Error creating tasks:", error);
    }
    return createdTasks;
  };

  const handleCreateFields = async (project) => {
    if (!project || fields.length === 0) return;
    await updateToken();
    const createdFields = [];
    try {
      for (let i = 0; i < tasks.length; i++) {
        const field = fields[i];
        const newField = {
          active: field.active,
          description: field.description,
          externalKey: field.externalKey,
          internalCode: field.internalCode,
          name: field.name,
          project: project,
        };
        const createdField = await timeslotsApi.createField(newField, token);
        fields[i] = { ...field, id: createdField.data.id };
        createdFields.push(createdField);
      }
    } catch (error) {
      console.error("Error creating fields:", error);
    }
    return createdFields;
  };

  const handleCreateSubtasks = async (project) => {
    if (!project || subtasks.length === 0) return;
    await updateToken();
    try {
      for (const subtask of subtasks) {
        const task = { id: tasks[subtask.task.originalTask.position].id };
        const field = { id: fields[subtask.field.originalField.position].id };
        const newSubtask = {
          active: subtask.active,
          description: subtask.description,
          externalKey: subtask.externalKey,
          internalCode: subtask.internalCode,
          name: subtask.name,
          task: task,
          field: field,
          project: project,
        };
        await timeslotsApi.createSubtask(newSubtask, token);
      }
    } catch (error) {
      console.error("Error creating subtasks:", error);
    }
  };

  const handleCreateOutputs = async () => {
    if (outputs.length === 0) return;
    await updateToken();
    try {
      for (const output of outputs) {
        const task = { id: tasks[output.task.originalTask.position].id };
        const newOutput = {
          active: output.active,
          description: output.description,
          externalKey: output.externalKey,
          internalCode: output.internalCode,
          name: output.name,
          task: task
        };
        await timeslotsApi.createOutput(newOutput, token);
      }
    } catch (error) {
      console.error("Error creating outputs:", error);
    }
  };

  const handleCreatePlaces = async (project) => {
    if (!project) return;
    await updateToken();
    try {
      for (const place of places) {
        const newPlace = {
          active: place.active,
          description: place.description,
          externalKey: place.externalKey,
          internalCode: place.internalCode,
          name: place.name,
          icon: place.icon,
          project: project,
        };
        await timeslotsApi.createPlace(newPlace, token);     
      }
    } catch (error) {
      console.error("Error creating places:", error);
    }
  };

  const handleCreateTemplate = async () => {
    await updateToken();
    try {
      const template = {
        active: true,
        templateData: templateData,
        name: templateName,
        description: templateDescription
      };
      await timeslotsApi.createTemplate(template, token);     
    } catch (error) {
      console.error("Error creating template:", error);
    }
  };

  const resetFormTemplate = useCallback(() => {
    setWorkingDaysHours(null);
    setMaximumAllowedHours(null);
    setContractualPremises("");
    setTemplateName("");
    setTemplateDescription("");
    setSavingTemplate(false);
  }, []);

  const resetWizard = useCallback(() => {
    resetFormProject();
    resetFormTemplate();
    setSelectedProjectPartners([]);
    setSelectedCustomers([]);
    setSelectKey((prevKey) => prevKey + 1);
    resetFormTasks();
    setTasks([]);
    resetFormFields();
    setFields([]);
    resetFormSubtasks();
    setSubtasks([]);
    resetFormOutputs();
    setOutputs([]);
    resetFormPlaces();
    setPlaces([]);
    setCurrentTabIndex(0);
  }, [
    resetFormProject,
    resetFormTemplate,
    resetFormTasks,
    resetFormFields,
    resetFormSubtasks,
    resetFormOutputs,
    resetFormPlaces,
]);

useEffect(() => {
  if (!show) {
    resetWizard();
  }
}, [show, resetWizard]);

  const handleComplete = async () => {
    const project = await handleCreateProject();
    if (project) {
      await handleCreateCustomers(project); 
      await handleCreateProjectPartners(project);
      await handleCreateTasks(project); 
      await handleCreateFields(project);
      await handleCreateSubtasks(project);
      await handleCreateOutputs();
      await handleCreatePlaces(project);
      await handleCreateProjectPreferences(project);
      if(savingTemplate) {
        await handleCreateTemplate();
      }
    }
    resetWizard();
    toast.success(t("projectSaved"));
    handleClose();
    console.log("Form completed!");
  };

  const openModalCreateResource = () => setShowModalCreateResource(true);
  const closeModalCreateResource = () => {
    setShowModalCreateResource(false);
    fetchResources();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="large-modal"
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title className="green-color">
          {t("projectCreation")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormWizard
          ref={formWizardRef}
          color={mainColor}
          stepSize="xs"
          onComplete={handleComplete}
          nextButtonTemplate={() => (
            <Button
              className="green-text-button fw-semibold border-none"
              onClick={nextTab}
            >
              {t("next")}
              <img
                src={`${process.env.PUBLIC_URL}/next.svg`}
                alt="Next"
                style={{
                  width: "1rem",
                }}
                className="ml-2 mb-1"
              />
            </Button>
          )}
          backButtonTemplate={() => (
            <Button
              className="green-text-button fw-semibold mr-2 border-none"
              onClick={backTab}
            >
              <img
                src={`${process.env.PUBLIC_URL}/back.svg`}
                alt="Back"
                style={{
                  width: "1rem",
                }}
                className="mr-2 mb-1"
              />
              {t("back")}
            </Button>
          )}
          finishButtonTemplate={(handleComplete) => (
            <Button
              className="white-text-button fw-semibold mr-2"
              onClick={handleComplete}
              disabled={!isAltchaVerified}
            >
              <img
                src={`${process.env.PUBLIC_URL}/save.svg`}
                alt="Back"
                style={{
                  width: "1rem",
                }}
                className="mr-2 mb-1"
              />
              {t("saveProject")}
            </Button>
          )}
        >
          <FormWizard.TabContent title={t("projectData")} icon=" ">
            <div className="row text-start">
              <div className="col-3">
                <div className="row">
                  <div className="col-12">
                    <Form.Group controlId="projectNameForm">
                      <Form.Label className="green-color">
                        {t("projectName")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("projectName")}
                        className="w-100 custom-form-control"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Form.Group controlId="projectCigForm">
                      <Form.Label className="green-color">
                        {t("projectCig")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("projectCig")}
                        value={projectCig}
                        className="custom-form-control"
                        onChange={(e) => setProjectCig(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group controlId="projectCupForm">
                      <Form.Label className="green-color">
                        {t("projectCup")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("projectCup")}
                        value={projectCup}
                        className="custom-form-control"
                        onChange={(e) => setProjectCup(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Form.Group controlId="projectInternalCodeForm">
                      <Form.Label className="green-color">
                        {t("internalCode")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("internalCode")}
                        className="custom-form-control"
                        value={projectInternalCode}
                        onChange={(e) => setProjectInternalCode(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group controlId="projectExternalCodeForm">
                      <Form.Label className="green-color">
                        {t("externalCode")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("externalCode")}
                        className="custom-form-control"
                        value={projectExternalCode}
                        onChange={(e) => setProjectExternalCode(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-12">
                    <Form.Group controlId="projectDescriptionForm">
                      <Form.Label className="green-color">
                        {t("projectDescription")}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={10}
                        placeholder={t("projectDescription")}
                        className="w-100 custom-form-control"
                        value={projectDescription}
                        onChange={(e) => setProjectDescription(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="col-3 mt-1">
                <div className="row light-green-background">
                  <span className="green-color mt-1">
                    {t("projectDuration")}
                  </span>
                  <div className="col-6">
                    <Form.Group controlId="projectStartDateForm">
                      <Form.Label className="green-color">
                        {t("projectStartDate")}
                        <img
                          src={`${process.env.PUBLIC_URL}/calendar1.svg`}
                          alt="Calendar"
                          style={{
                            width: "1rem",
                          }}
                          className="ml-2 mb-1"
                        />
                      </Form.Label>
                      <DatePicker
                        selected={projectStartDate}
                        onChange={(date) => setProjectStartDate(date)}
                        placeholderText={t("projectStartDate")}
                        locale={getLocale()}
                        dateFormat="dd/MM/yyyy"
                        className="form-control custom-form-control"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                  <AltchaComponent challengeUrl={challengeUrl} />
                  </div>
                  <div className="col-6">
                    <Form.Group controlId="projectEndDateForm">
                      <Form.Label className="green-color">
                        {t("projectEndDate")}
                        <img
                          src={`${process.env.PUBLIC_URL}/calendar2.svg`}
                          alt="Calendar"
                          style={{
                            width: "1rem",
                          }}
                          className="ml-2 mb-1"
                        />
                      </Form.Label>
                      <DatePicker
                        selected={projectEndDate}
                        onChange={(date) => setProjectEndDate(date)}
                        placeholderText={t("projectEndDate")}
                        locale={getLocale()}
                        dateFormat="dd/MM/yyyy"
                        className="form-control custom-form-control"
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Form.Group controlId="projectCostCenterForm">
                      <Form.Label className="green-color">
                        {t("projectCostCenter")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("projectCostCenter")}
                        className="custom-form-control"
                        value={projectCostCenter}
                        onChange={(e) => setProjectCostCenter(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group controlId="projectValueForm">
                      <Form.Label className="green-color">
                        {t("projectValue")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("projectValue")}
                        className="custom-form-control"
                        value={projectValue}
                        onChange={(e) =>
                          setProjectValue(parseFloat(e.target.value))
                        }
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row light-green-background">
                  <div className="col-12">
                    <Form.Group
                      controlId="projectActiveSwitch"
                      className="d-flex justify-content-end align-items-center"
                    >
                      <Form.Label className="mb-0 mr-1">
                        {t("projectActive")}
                      </Form.Label>
                      <Form.Check
                        type="switch"
                        id="setActiveSwitch"
                        className="custom-switch"
                        checked={projectActive}
                        onChange={(e) => setProjectActive(e.target.checked)}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </FormWizard.TabContent>
          <FormWizard.TabContent title={t("templateSelection")} icon=" ">
            <div className="row text-start">
              <div className="col-3">
                <div className="row">
                  <div className="col-12">
                    <Form.Group controlId="templateForm">
                      <Form.Label className="green-color">
                        {t("template")}
                      </Form.Label>
                      <Select
                        classNamePrefix="custom-select"
                        placeholder={t("templatePlaceholder")}
                        value={selectedTemplate}
                        onChange={setSelectedTemplate}
                        options={templates}
                        noOptionsMessage={() => t("templatesNoOptions")}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: mainColor,
                            primary: mainColor,
                            neutral20: "#dee2e6",
                          },
                        })}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Form.Group controlId="templateWorkingDaysHoursForm">
                      <Form.Label className="green-color">
                        {t("templateWorkingDaysHours")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="custom-form-control"
                        value={workingDaysHours}
                        onChange={handleChangeWorkingDaysHours}
                        placeholder="HH:mm"
                        isValid={workingDaysHours && isWorkingDaysHoursValid}  
                        isInvalid={workingDaysHours && !isWorkingDaysHoursValid} 
                      />
                      <Form.Control.Feedback type="invalid">
                        {t("timeValidationMessage")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group controlId="templateMaximumAllowedHoursForm">
                      <Form.Label className="green-color">
                        {t("templateMaximumAllowedHours")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="custom-form-control"
                        value={maximumAllowedHours}
                        onChange={handleChangeMaximumAllowedHours}
                        placeholder="HH:mm"
                        isValid={maximumAllowedHours && isMaximumAllowedHoursValid}  
                        isInvalid={maximumAllowedHours && !isMaximumAllowedHoursValid}  
                      />
                      <Form.Control.Feedback type="invalid">
                        {t("timeValidationMessage")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-12">
                    <Form.Group controlId="contractualPremisesForm">
                      <Form.Label className="green-color">
                        {t("contractualPremises")}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={10}
                        value={contractualPremises}
                        onChange={(e) => setContractualPremises(e.target.value)}
                        placeholder={t("contractualPremises")}
                        className="w-100 custom-form-control"
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="col-3 mt-4 pt-4">
                <div className="row">
                  <div className="col-12 light-green-background">
                    <Form.Group controlId="templateFieldsSwitchGroup">
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Label className="mb-0">{t("fields")}</Form.Label>
                        <div className="d-flex align-items-center">
                          <div
                            className={`mr-2 ${
                              switches.templateFieldsActive
                                ? "text-gray"
                                : "text-black-semibold"
                            }`}
                          >
                            OFF
                          </div>
                          <Form.Check
                            type="switch"
                            id="templateFieldsSwitch"
                            className="custom-switch mx-0"
                            checked={switches.templateFieldsActive}
                            onChange={() =>
                              handleSwitch("templateFieldsActive")
                            }
                          />
                          <div
                            className={`mr-2 ${
                              switches.templateFieldsActive
                                ? "text-black-semibold"
                                : "text-gray"
                            }`}
                          >
                            ON
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-12">
                    <Form.Group controlId="templateSubtaskSwitchGroup">
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Label className="mb-0">{t("subtask")}</Form.Label>
                        <div className="d-flex align-items-center">
                          <div
                            className={`mr-2 ${
                              switches.templateSubtaskActive
                                ? "text-gray"
                                : "text-black-semibold"
                            }`}
                          >
                            OFF
                          </div>
                          <Form.Check
                            type="switch"
                            id="templateSubtaskSwitch"
                            className="custom-switch mx-0"
                            checked={switches.templateSubtaskActive}
                            onChange={() =>
                              handleSwitch("templateSubtaskActive")
                            }
                          />
                          <div
                            className={`mr-2 ${
                              switches.templateSubtaskActive
                                ? "text-black-semibold"
                                : "text-gray"
                            }`}
                          >
                            ON
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-12 light-green-background">
                    <Form.Group controlId="templateFieldSubtaskSwitchGroup">
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Label className="mb-0">
                          {t("fieldSubtask")}
                        </Form.Label>
                        <div className="d-flex align-items-center">
                          <div
                            className={`mr-2 ${
                              switches.templateFieldSubtaskActive
                                ? "text-gray"
                                : "text-black-semibold"
                            }`}
                          >
                            OFF
                          </div>
                          <Form.Check
                            type="switch"
                            id="templateFieldSubtaskSwitch"
                            className="custom-switch mx-0"
                            checked={switches.templateFieldSubtaskActive}
                            onChange={() =>
                              handleSwitch("templateFieldSubtaskActive")
                            }
                          />
                          <div
                            className={`mr-2 ${
                              switches.templateFieldSubtaskActive
                                ? "text-black-semibold"
                                : "text-gray"
                            }`}
                          >
                            ON
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-12">
                    <Form.Group controlId="templatePlacesSwitchGroup">
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Label className="mb-0">{t("places")}</Form.Label>
                        <div className="d-flex align-items-center">
                          <div
                            className={`mr-2 ${
                              switches.templatePlacesActive
                                ? "text-gray"
                                : "text-black-semibold"
                            }`}
                          >
                            OFF
                          </div>
                          <Form.Check
                            type="switch"
                            id="templatePlacesSwitch"
                            className="custom-switch mx-0"
                            checked={switches.templatePlacesActive}
                            onChange={() =>
                              handleSwitch("templatePlacesActive")
                            }
                          />
                          <div
                            className={`mr-2 ${
                              switches.templatePlacesActive
                                ? "text-black-semibold"
                                : "text-gray"
                            }`}
                          >
                            ON
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-12 light-green-background">
                    <Form.Group controlId="templateNotesSwitchGroup">
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Label className="mb-0">{t("notes")}</Form.Label>
                        <div className="d-flex align-items-center">
                          <div
                            className={`mr-2 ${
                              switches.templateNotesActive
                                ? "text-gray"
                                : "text-black-semibold"
                            }`}
                          >
                            OFF
                          </div>
                          <Form.Check
                            type="switch"
                            id="templateNotesSwitch"
                            className="custom-switch mx-0"
                            checked={switches.templateNotesActive}
                            onChange={() => handleSwitch("templateNotesActive")}
                          />
                          <div
                            className={`mr-2 ${
                              switches.templateNotesActive
                                ? "text-black-semibold"
                                : "text-gray"
                            }`}
                          >
                            ON
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-12">
                    <Form.Group controlId="templateOutputSwitchGroup">
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Label className="mb-0">{t("output")}</Form.Label>
                        <div className="d-flex align-items-center">
                          <div
                            className={`mr-2 ${
                              switches.templateOutputActive
                                ? "text-gray"
                                : "text-black-semibold"
                            }`}
                          >
                            OFF
                          </div>
                          <Form.Check
                            type="switch"
                            id="templateOutputSwitch"
                            className="custom-switch mx-0"
                            checked={switches.templateOutputActive}
                            onChange={() =>
                              handleSwitch("templateOutputActive")
                            }
                          />
                          <div
                            className={`mr-2 ${
                              switches.templateOutputActive
                                ? "text-black-semibold"
                                : "text-gray"
                            }`}
                          >
                            ON
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-12 light-green-background">
                    <Form.Group controlId="templateHolidaysConsentSwitchGroup">
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Label className="mb-0">
                          {t("holidaysConsent")}
                        </Form.Label>
                        <div className="d-flex align-items-center">
                          <div
                            className={`mr-2 ${
                              switches.templateHolidaysConsentActive
                                ? "text-gray"
                                : "text-black-semibold"
                            }`}
                          >
                            OFF
                          </div>
                          <Form.Check
                            type="switch"
                            id="templateHolidaysConsentSwitch"
                            className="custom-switch mx-0"
                            checked={switches.templateHolidaysConsentActive}
                            onChange={() =>
                              handleSwitch("templateHolidaysConsentActive")
                            }
                          />
                          <div
                            className={`mr-2 ${
                              switches.templateHolidaysConsentActive
                                ? "text-black-semibold"
                                : "text-gray"
                            }`}
                          >
                            ON
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </FormWizard.TabContent>
          <FormWizard.TabContent title={t("stakeholders")} icon=" ">
            <div className="row text-start">
              <div className="col-6">
                <div className="row">
                  <div className="col-8">
                    <Form.Group controlId="projectPartnersForm">
                      <Form.Label className="green-color">
                        {t("projectPartners")}
                      </Form.Label>
                      <Select
                        key={selectKey}
                        classNamePrefix="custom-select"
                        placeholder={t("projectPartnersPlaceholder")}
                        value={selectedProjectPartners}
                        onChange={setSelectedProjectPartners}
                        options={resources}
                        isMulti
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: mainColor,
                            primary: mainColor,
                            neutral20: "#dee2e6",
                          },
                        })}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-4 mt-4">
                    <div className="row">
                      <div className="col-12">
                        <Button className="green-text-button fw-semibold" onClick={openModalCreateResource}>
                          <img
                            src={`${process.env.PUBLIC_URL}/add.svg`}
                            alt="Next"
                            style={{
                              width: "1rem",
                            }}
                            className="mr-2 mb-1"
                          />
                          {t("addResource")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-8">
                    <Form.Group controlId="customersForm">
                      <Form.Label className="green-color">
                        {t("customers")}
                      </Form.Label>
                      <Select
                        key={selectKey}
                        classNamePrefix="custom-select"
                        placeholder={t("customerPlaceholder")}
                        value={selectedCustomers}
                        onChange={setSelectedCustomers}
                        options={resources}
                        isMulti
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: mainColor,
                            primary: mainColor,
                            neutral20: "#dee2e6",
                          },
                        })}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-4 mt-4">
                    <div className="row">
                      <div className="col-12">
                        <Button className="green-text-button fw-semibold" onClick={openModalCreateResource}>
                          <img
                            src={`${process.env.PUBLIC_URL}/add.svg`}
                            alt="Next"
                            style={{
                              width: "1rem",
                            }}
                            className="mr-2 mb-1"
                          />
                          {t("addResource")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row text-start">
              <div className="col-12">
                {selectedProjectPartners &&
                selectedCustomers &&
                selectedProjectPartners.some((partner) =>
                  selectedCustomers.includes(partner)
                ) ? (
                  <span className="alert alert-warning">
                    {t("duplicateStakeholdersWarning")}
                  </span>
                ) : null}
              </div>
            </div>
          </FormWizard.TabContent>
          <FormWizard.TabContent title={t("task")} icon=" ">
            <div className="row text-start">
              <div className="col-4">
                <div className="row">
                  <div className="col-12">
                    <div className="green-color form-label">{t("task")}</div>
                    {tasks.length === 0 ? (
                      <div className="alert alert-warning">
                        {t("noTaskAddedYet")}
                      </div>
                    ) : (
                      <ListGroup variant="flush">
                        {tasks.map((task, index) => (
                          <ListGroup.Item
                            key={index}
                            action
                            onClick={() => handleSelectTask(index)}
                            className="pl-2 pb-1"
                          >
                            <div>
                              <span className="green-text">
                                {task.internalCode}
                              </span>{" "}
                              - {task.name}
                            </div>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div className="row">
                  <div className="col-4">
                    <Form.Group controlId="nameForm">
                      <Form.Label className="green-color">
                        {t("name")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("name")}
                        className="w-100 custom-form-control"
                        value={taskName}
                        onChange={(e) => setTaskName(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-8">
                    <Form.Group controlId="descriptionForm">
                      <Form.Label className="green-color">
                        {t("description")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("description")}
                        className="w-100 custom-form-control"
                        value={taskDescription}
                        onChange={(e) => setTaskDescription(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <Form.Group controlId="categoryForm">
                      <Form.Label className="green-color">
                        {t("category")}
                      </Form.Label>
                      <Select
                        classNamePrefix="custom-select"
                        placeholder={t("categoryPlaceholder")}
                        value={selectedTaskCategory}
                        onChange={setSelectedTaskCategory}
                        options={categories}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: mainColor,
                            primary: mainColor,
                            neutral20: "#dee2e6",
                          },
                        })}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-3">
                    <Form.Group controlId="internalCodeForm">
                      <Form.Label className="green-color">
                        {t("internalCode")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("internalCode")}
                        className="w-100 custom-form-control"
                        value={taskInternalCode}
                        onChange={(e) => setTaskInternalCode(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-3">
                    <Form.Group controlId="externalCodeForm">
                      <Form.Label className="green-color">
                        {t("externalCode")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("externalCode")}
                        className="w-100 custom-form-control"
                        value={taskExternalCode}
                        onChange={(e) => setTaskExternalCode(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-2 mt-4 pt-4">
                    <Form.Group
                      controlId="taskSwitchGroup"
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div className="d-flex align-items-center">
                        <div
                          className={`mr-2 ${
                            switches.taskActive
                              ? "text-gray"
                              : "text-black-semibold"
                          }`}
                        >
                          OFF
                        </div>
                        <Form.Check
                          type="switch"
                          id="taskActiveSwitch"
                          className="custom-switch mx-0"
                          checked={switches.taskActive}
                          onChange={() => handleSwitch("taskActive")}
                        />
                        <div
                          className={`mr-2 ${
                            switches.taskActive
                              ? "text-black-semibold"
                              : "text-gray"
                          }`}
                        >
                          ON
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6"></div>
                  <div className="col-6 d-flex justify-content-end">
                    <Button
                      className="green-text-button fw-semibold me-2"
                      onClick={handleDeleteTask}
                      disabled={selectedTaskIndex === null}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/delete.svg`}
                        alt="Delete"
                        style={{
                          width: "1rem",
                        }}
                        className="mr-2 mb-1"
                      />
                      {t("delete")}
                    </Button>
                    <Button
                      className="green-text-button fw-semibold me-2"
                      onClick={handleEditTask}
                      disabled={selectedTaskIndex === null}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/edit.svg`}
                        alt="Edit"
                        style={{
                          width: "1rem",
                        }}
                        className="mr-2 mb-1"
                      />
                      {t("edit")}
                    </Button>
                    <Button
                      className="green-text-button fw-semibold"
                      onClick={handleAddTask}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/add.svg`}
                        alt="Add"
                        style={{
                          width: "1rem",
                        }}
                        className="mr-2 mb-1"
                      />
                      {t("add")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </FormWizard.TabContent>
          <FormWizard.TabContent title={t("fields")} icon=" ">
            <div className="row text-start">
              <div className="col-4">
                <div className="row">
                  <div className="col-12">
                    <div className="green-color form-label">{t("fields")}</div>
                    {fields.length === 0 ? (
                      <div className="alert alert-warning">
                        {t("noFieldAddedYet")}
                      </div>
                    ) : (
                      <ListGroup variant="flush">
                        {fields.map((field, index) => (
                          <ListGroup.Item
                            key={index}
                            action
                            onClick={() => handleSelectField(index)}
                            className="pl-2 pb-1"
                          >
                            <div>
                              <span className="green-text">
                                {field.internalCode}
                              </span>{" "}
                              - {field.name}
                            </div>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div className="row">
                  <div className="col-4">
                    <Form.Group controlId="nameForm">
                      <Form.Label className="green-color">
                        {t("name")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("name")}
                        className="w-100 custom-form-control"
                        value={fieldName}
                        onChange={(e) => setFieldName(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-8">
                    <Form.Group controlId="descriptionForm">
                      <Form.Label className="green-color">
                        {t("description")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("description")}
                        className="w-100 custom-form-control"
                        value={fieldDescription}
                        onChange={(e) => setFieldDescription(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <Form.Group controlId="internalCodeForm">
                      <Form.Label className="green-color">
                        {t("internalCode")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("internalCode")}
                        className="w-100 custom-form-control"
                        value={fieldInternalCode}
                        onChange={(e) => setFieldInternalCode(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group controlId="externalCodeForm">
                      <Form.Label className="green-color">
                        {t("externalCode")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("externalCode")}
                        className="w-100 custom-form-control"
                        value={fieldExternalCode}
                        onChange={(e) => setFieldExternalCode(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-2 mt-4 pt-4">
                    <Form.Group
                      controlId="fieldSwitchGroup"
                      className="d-flex justify-content-end align-items-center"
                    >
                      <div className="d-flex align-items-center">
                        <div
                          className={`mr-2 ${
                            switches.fieldActive
                              ? "text-gray"
                              : "text-black-semibold"
                          }`}
                        >
                          OFF
                        </div>
                        <Form.Check
                          type="switch"
                          id="fieldActiveSwitch"
                          className="custom-switch mx-0"
                          checked={switches.fieldActive}
                          onChange={() => handleSwitch("fieldActive")}
                        />
                        <div
                          className={`mr-2 ${
                            switches.fieldActive
                              ? "text-black-semibold"
                              : "text-gray"
                          }`}
                        >
                          ON
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6"></div>
                  <div className="col-6 d-flex justify-content-end">
                    <Button
                      className="green-text-button fw-semibold me-2"
                      onClick={handleDeleteField}
                      disabled={selectedFieldIndex === null}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/delete.svg`}
                        alt="Delete"
                        style={{
                          width: "1rem",
                        }}
                        className="mr-2 mb-1"
                      />
                      {t("delete")}
                    </Button>
                    <Button
                      className="green-text-button fw-semibold me-2"
                      onClick={handleEditField}
                      disabled={selectedFieldIndex === null}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/edit.svg`}
                        alt="Edit"
                        style={{
                          width: "1rem",
                        }}
                        className="mr-2 mb-1"
                      />
                      {t("edit")}
                    </Button>
                    <Button
                      className="green-text-button fw-semibold"
                      onClick={handleAddField}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/add.svg`}
                        alt="Add"
                        style={{
                          width: "1rem",
                        }}
                        className="mr-2 mb-1"
                      />
                      {t("add")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </FormWizard.TabContent>
          <FormWizard.TabContent title={t("subtask")} icon=" ">
            <div className="row text-start">
              <div className="col-4">
                <div className="row">
                  <div className="col-12">
                    <div className="green-color form-label">{t("subtask")}</div>
                    {subtasks.length === 0 ? (
                      <div className="alert alert-warning">
                        {t("noSubtaskAddedYet")}
                      </div>
                    ) : (
                      <ListGroup variant="flush">
                        {subtasks.map((subtask, index) => (
                          <ListGroup.Item
                            key={index}
                            action
                            onClick={() => handleSelectSubtask(index)}
                            className="pl-2 pb-1"
                          >
                            <div>
                              <span className="green-text">
                                {subtask.internalCode}
                              </span>{" "}
                              - {subtask.name}
                            </div>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div className="row">
                  <div className="col-4">
                    <Form.Group controlId="nameForm">
                      <Form.Label className="green-color">
                        {t("name")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("name")}
                        className="w-100 custom-form-control"
                        value={subtaskName}
                        onChange={(e) => setSubtaskName(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-8">
                    <Form.Group controlId="descriptionForm">
                      <Form.Label className="green-color">
                        {t("description")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("description")}
                        className="w-100 custom-form-control"
                        value={subtaskDescription}
                        onChange={(e) => setSubtaskDescription(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Form.Group controlId="taskSubtaskForm">
                      <Form.Label className="green-color">
                        {t("task")}
                      </Form.Label>
                      <Select
                        classNamePrefix="custom-select"
                        placeholder={t("taskPlaceholder")}
                        value={selectedTaskSubtask}
                        onChange={setSelectedTaskSubtask}
                        options={taskOptions}
                        noOptionsMessage={() => t("taskNoOptions")}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: mainColor,
                            primary: mainColor,
                            neutral20: "#dee2e6",
                          },
                        })}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group controlId="fieldSubtaskForm">
                      <Form.Label className="green-color">
                        {t("field")}
                      </Form.Label>
                      <Select
                        classNamePrefix="custom-select"
                        placeholder={t("fieldPlaceholder")}
                        value={selectedFieldSubtask}
                        onChange={setSelectedFieldSubtask}
                        options={fieldOptions}
                        noOptionsMessage={() => t("fieldNoOptions")}
                        isDisabled={!switches.templateFieldsActive || (switches.templateFieldsActive && !switches.templateFieldSubtaskActive)}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: mainColor,
                            primary: mainColor,
                            neutral20: "#dee2e6",
                          },
                        })}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <Form.Group controlId="internalCodeForm">
                      <Form.Label className="green-color">
                        {t("internalCode")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("internalCode")}
                        className="w-100 custom-form-control"
                        value={subtaskInternalCode}
                        onChange={(e) => setSubtaskInternalCode(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group controlId="externalCodeForm">
                      <Form.Label className="green-color">
                        {t("externalCode")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("externalCode")}
                        className="w-100 custom-form-control"
                        value={subtaskExternalCode}
                        onChange={(e) => setSubtaskExternalCode(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-2 mt-4 pt-4">
                    <Form.Group
                      controlId="subtaskSwitchGroup"
                      className="d-flex justify-content-end align-items-center"
                    >
                      <div className="d-flex align-items-center">
                        <div
                          className={`mr-2 ${
                            switches.subtaskActive
                              ? "text-gray"
                              : "text-black-semibold"
                          }`}
                        >
                          OFF
                        </div>
                        <Form.Check
                          type="switch"
                          id="subtaskActiveSwitch"
                          className="custom-switch mx-0"
                          checked={switches.subtaskActive}
                          onChange={() => handleSwitch("subtaskActive")}
                        />
                        <div
                          className={`mr-2 ${
                            switches.subtaskActive
                              ? "text-black-semibold"
                              : "text-gray"
                          }`}
                        >
                          ON
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6"></div>
                  <div className="col-6 d-flex justify-content-end">
                    <Button
                      className="green-text-button fw-semibold me-2"
                      onClick={handleDeleteSubtask}
                      disabled={selectedSubtaskIndex === null}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/delete.svg`}
                        alt="Delete"
                        style={{
                          width: "1rem",
                        }}
                        className="mr-2 mb-1"
                      />
                      {t("delete")}
                    </Button>
                    <Button
                      className="green-text-button fw-semibold me-2"
                      onClick={handleEditSubtask}
                      disabled={selectedSubtaskIndex === null}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/edit.svg`}
                        alt="Edit"
                        style={{
                          width: "1rem",
                        }}
                        className="mr-2 mb-1"
                      />
                      {t("edit")}
                    </Button>
                    <Button
                      className="green-text-button fw-semibold"
                      onClick={handleAddSubtask}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/add.svg`}
                        alt="Add"
                        style={{
                          width: "1rem",
                        }}
                        className="mr-2 mb-1"
                      />
                      {t("add")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </FormWizard.TabContent>
          <FormWizard.TabContent title={t("output")} icon=" ">
            <div className="row text-start">
              <div className="col-4">
                <div className="row">
                  <div className="col-12">
                    <div className="green-color form-label">{t("output")}</div>
                    {outputs.length === 0 ? (
                      <div className="alert alert-warning">
                        {t("noOutputAddedYet")}
                      </div>
                    ) : (
                      <ListGroup variant="flush">
                        {outputs.map((output, index) => (
                          <ListGroup.Item
                            key={index}
                            action
                            onClick={() => handleSelectOutput(index)}
                            className="pl-2 pb-1"
                          >
                            <div>
                              <span className="green-text">
                                {output.internalCode}
                              </span>{" "}
                              - {output.name}
                            </div>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div className="row">
                  <div className="col-4">
                    <Form.Group controlId="nameForm">
                      <Form.Label className="green-color">
                        {t("name")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("name")}
                        className="w-100 custom-form-control"
                        value={outputName}
                        onChange={(e) => setOutputName(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-8">
                    <Form.Group controlId="descriptionForm">
                      <Form.Label className="green-color">
                        {t("description")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("description")}
                        className="w-100 custom-form-control"
                        value={outputDescription}
                        onChange={(e) => setOutputDescription(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Form.Group controlId="taskOutputForm">
                      <Form.Label className="green-color">
                        {t("task")}
                      </Form.Label>
                      <Select
                        classNamePrefix="custom-select"
                        placeholder={t("taskPlaceholder")}
                        value={selectedTaskOutput}
                        onChange={setSelectedTaskOutput}
                        options={taskOptions}
                        noOptionsMessage={() => t("taskNoOptions")}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: mainColor,
                            primary: mainColor,
                            neutral20: "#dee2e6",
                          },
                        })}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <Form.Group controlId="internalCodeForm">
                      <Form.Label className="green-color">
                        {t("internalCode")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("internalCode")}
                        className="w-100 custom-form-control"
                        value={outputInternalCode}
                        onChange={(e) => setOutputInternalCode(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group controlId="externalCodeForm">
                      <Form.Label className="green-color">
                        {t("externalCode")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("externalCode")}
                        className="w-100 custom-form-control"
                        value={outputExternalCode}
                        onChange={(e) => setOutputExternalCode(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-2 mt-4 pt-4">
                    <Form.Group
                      controlId="outputSwitchGroup"
                      className="d-flex justify-content-end align-items-center"
                    >
                      <div className="d-flex align-items-center">
                        <div
                          className={`mr-2 ${
                            switches.outputActive
                              ? "text-gray"
                              : "text-black-semibold"
                          }`}
                        >
                          OFF
                        </div>
                        <Form.Check
                          type="switch"
                          id="outputActiveSwitch"
                          className="custom-switch mx-0"
                          checked={switches.outputActive}
                          onChange={() => handleSwitch("outputActive")}
                        />
                        <div
                          className={`mr-2 ${
                            switches.outputActive
                              ? "text-black-semibold"
                              : "text-gray"
                          }`}
                        >
                          ON
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6"></div>
                  <div className="col-6 d-flex justify-content-end">
                    <Button
                      className="green-text-button fw-semibold me-2"
                      onClick={handleDeleteOutput}
                      disabled={selectedOutputIndex === null}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/delete.svg`}
                        alt="Delete"
                        style={{
                          width: "1rem",
                        }}
                        className="mr-2 mb-1"
                      />
                      {t("delete")}
                    </Button>
                    <Button
                      className="green-text-button fw-semibold me-2"
                      onClick={handleEditOutput}
                      disabled={selectedOutputIndex === null}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/edit.svg`}
                        alt="Edit"
                        style={{
                          width: "1rem",
                        }}
                        className="mr-2 mb-1"
                      />
                      {t("edit")}
                    </Button>
                    <Button
                      className="green-text-button fw-semibold"
                      onClick={handleAddOutput}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/add.svg`}
                        alt="Add"
                        style={{
                          width: "1rem",
                        }}
                        className="mr-2 mb-1"
                      />
                      {t("add")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </FormWizard.TabContent>
          <FormWizard.TabContent title={t("places")} icon=" ">
            <div className="row text-start">
              <div className="col-4">
                <div className="row">
                  <div className="col-12">
                    <div className="green-color form-label">{t("places")}</div>
                    {places.length === 0 ? (
                      <div className="alert alert-warning">
                        {t("noPlaceAddedYet")}
                      </div>
                    ) : (
                      <ListGroup variant="flush">
                        {places.map((place, index) => (
                          <ListGroup.Item
                            key={index}
                            action
                            onClick={() => handleSelectPlace(index)}
                            className="pl-2 pb-1"
                          >
                            <div>
                              <span className="green-text">
                                {place.internalCode}
                              </span>{" "}
                              - {place.name}
                            </div>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div className="row">
                  <div className="col-4">
                    <Form.Group controlId="nameForm">
                      <Form.Label className="green-color">
                        {t("name")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("name")}
                        className="w-100 custom-form-control"
                        value={placeName}
                        onChange={(e) => setPlaceName(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-8">
                    <Form.Group controlId="descriptionForm">
                      <Form.Label className="green-color">
                        {t("description")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("description")}
                        className="w-100 custom-form-control"
                        value={placeDescription}
                        onChange={(e) => setPlaceDescription(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="row">
                      <div className="col-6">
                        <Form.Check
                          type="radio"
                          id="officeRadio"
                          className="custom-switch"
                          name="placeType"
                          label={
                            <span className="d-flex align-items-center">
                              <img
                                src={`${process.env.PUBLIC_URL}/office.svg`}
                                alt="Office"
                                style={{
                                  width: "1rem",
                                }}
                                className="me-1"
                              />
                              {t("office")}
                            </span>
                          }
                          value="office"
                          checked={placeType === "office"}
                          onChange={handlePlaceTypeChange}
                        />
                      </div>
                      <div className="col-6">
                        <Form.Check
                          type="radio"
                          id="remotelyRadio"
                          className="custom-switch"
                          name="placeType"
                          label={
                            <span className="d-flex align-items-center">
                              <img
                                src={`${process.env.PUBLIC_URL}/remotely.svg`}
                                alt="Remotely"
                                style={{
                                  width: "1rem",
                                }}
                                className="me-1"
                              />
                              {t("remotely")}
                            </span>
                          }
                          value="remotely"
                          checked={placeType === "remotely"}
                          onChange={handlePlaceTypeChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <Form.Check
                          type="radio"
                          id="customerRadio"
                          className="custom-switch"
                          name="placeType"
                          label={
                            <span className="d-flex align-items-center">
                              <img
                                src={`${process.env.PUBLIC_URL}/customer.svg`}
                                alt="Customer"
                                style={{
                                  width: "1rem",
                                }}
                                className="me-1"
                              />
                              {t("customer")}
                            </span>
                          }
                          value="customer"
                          checked={placeType === "customer"}
                          onChange={handlePlaceTypeChange}
                        />
                      </div>
                      <div className="col-6">
                        <Form.Check
                          type="radio"
                          id="travelRadio"
                          className="custom-switch"
                          name="placeType"
                          label={
                            <span className="d-flex align-items-center">
                              <img
                                src={`${process.env.PUBLIC_URL}/travel.svg`}
                                alt="Travel"
                                style={{
                                  width: "1rem",
                                }}
                                className="me-1"
                              />
                              {t("travel")}
                            </span>
                          }
                          value="travel"
                          checked={placeType === "travel"}
                          onChange={handlePlaceTypeChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <Form.Group controlId="internalCodeForm">
                      <Form.Label className="green-color">
                        {t("internalCode")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("internalCode")}
                        className="w-100 custom-form-control"
                        value={placeInternalCode}
                        onChange={(e) => setPlaceInternalCode(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-3">
                    <Form.Group controlId="externalCodeForm">
                      <Form.Label className="green-color">
                        {t("externalCode")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("externalCode")}
                        className="w-100 custom-form-control"
                        value={placeExternalCode}
                        onChange={(e) => setPlaceExternalCode(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-2 mt-4 pt-4">
                    <Form.Group
                      controlId="placeSwitchGroup"
                      className="d-flex justify-content-end align-items-center"
                    >
                      <div className="d-flex align-items-center">
                        <div
                          className={`mr-2 ${
                            switches.placeActive
                              ? "text-gray"
                              : "text-black-semibold"
                          }`}
                        >
                          OFF
                        </div>
                        <Form.Check
                          type="switch"
                          id="placeActiveSwitch"
                          className="custom-switch mx-0"
                          checked={switches.placeActive}
                          onChange={() => handleSwitch("placeActive")}
                        />
                        <div
                          className={`mr-2 ${
                            switches.placeActive
                              ? "text-black-semibold"
                              : "text-gray"
                          }`}
                        >
                          ON
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6"></div>
                  <div className="col-6 d-flex justify-content-end">
                    <Button
                      className="green-text-button fw-semibold me-2"
                      onClick={handleDeletePlace}
                      disabled={selectedPlaceIndex === null}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/delete.svg`}
                        alt="Delete"
                        style={{
                          width: "1rem",
                        }}
                        className="mr-2 mb-1"
                      />
                      {t("delete")}
                    </Button>
                    <Button
                      className="green-text-button fw-semibold me-2"
                      onClick={handleEditPlace}
                      disabled={selectedPlaceIndex === null}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/edit.svg`}
                        alt="Edit"
                        style={{
                          width: "1rem",
                        }}
                        className="mr-2 mb-1"
                      />
                      {t("edit")}
                    </Button>
                    <Button
                      className="green-text-button fw-semibold"
                      onClick={handleAddPlace}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/add.svg`}
                        alt="Add"
                        style={{
                          width: "1rem",
                        }}
                        className="mr-2 mb-1"
                      />
                      {t("add")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </FormWizard.TabContent>
          <FormWizard.TabContent title={t("dataConfirmation")} icon=" ">
            <div className="row text-start">
              <div className="col-3">
                <div className="row">
                  <div className="col-12">
                    <Form.Group controlId="projectNameForm">
                      <Form.Label className="green-color">
                        {t("projectName")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("projectName")}
                        className="w-100 custom-form-control"
                        value={projectName}
                        disabled
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Form.Group controlId="projectCigForm">
                      <Form.Label className="green-color">
                        {t("projectCig")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="custom-form-control"
                        placeholder={t("projectCig")}
                        value={projectCig}
                        disabled
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group controlId="projectCupForm">
                      <Form.Label className="green-color">
                        {t("projectCup")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="custom-form-control"
                        placeholder={t("projectCup")}
                        value={projectCup}
                        disabled
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Form.Group controlId="projectInternalCodeForm">
                      <Form.Label className="green-color">
                        {t("internalCode")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="custom-form-control"
                        placeholder={t("internalCode")}
                        value={projectInternalCode}
                        disabled
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group controlId="projectExternalCodeForm">
                      <Form.Label className="green-color">
                        {t("externalCode")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="custom-form-control"
                        placeholder={t("externalCode")}
                        value={projectExternalCode}
                        disabled
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Form.Check
                      type="checkbox"
                      id="saveTemplateCheckbox"
                      className="custom-switch light-green-background"
                      label={t("saveTemplate")}
                      checked={savingTemplate}
                      onChange={(e) => setSavingTemplate(e.target.checked)}
                    />
                    
                  </div>
                </div>
                {savingTemplate && (
                  <div className="row">
                    <div className="col-12">
                      <Form.Group controlId="templateNameForm">
                        <Form.Label className="green-color">
                          {t("name")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="w-100 custom-form-control"
                          placeholder={t("name")}
                          value={templateName}
                          onChange={(e) => setTemplateName(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-12">
                      <Form.Group controlId="templateDescriptionForm">
                        <Form.Label className="green-color">
                          {t("description")}
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={10}
                          placeholder={t("description")}
                          className="w-100 custom-form-control"
                          value={templateDescription}
                          onChange={(e) => setTemplateDescription(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-9">
                <div className="row">
                  <div className="col-4">
                    <div className="col-12 light-green-background pt-0">
                      <span className="green-color">
                        {t("projectPartners")}
                      </span>
                    </div>
                    <ListGroup variant="flush">
                        {selectedProjectPartners.map((selectedProjectPartner, index) => (
                          <ListGroup.Item
                            key={index}
                            className="pl-2 pb-1"
                          >
                            <div>
                              <span className="green-text">
                                {selectedProjectPartner.label}
                              </span>
                            </div>
                          </ListGroup.Item>
                        ))}
                    </ListGroup>
                  </div>
                  <div className="col-4">
                    <div className="col-12 light-green-background pt-0">
                      <span className="green-color">{t("customers")}</span>
                    </div>
                    <ListGroup variant="flush">
                        {selectedCustomers.map((selectedCustomer, index) => (
                          <ListGroup.Item
                            key={index}
                            className="pl-2 pb-1"
                          >
                            <div>
                              <span className="green-text">
                                {selectedCustomer.label}
                              </span>
                            </div>
                          </ListGroup.Item>
                        ))}
                    </ListGroup>
                  </div>
                  <div className="col-4">
                    <div className="col-12 light-green-background pt-0">
                      <span className="green-color">{t("task")}</span>
                    </div>
                    <ListGroup variant="flush">
                        {tasks.map((task, index) => (
                          <ListGroup.Item
                            key={index}
                            className="pl-2 pb-1"
                          >
                            <div>
                              <span className="green-text">
                                {task.internalCode}
                              </span>{" "}
                              - {task.name}
                            </div>
                          </ListGroup.Item>
                        ))}
                    </ListGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <div className="col-12 light-green-background pt-0">
                      <span className="green-color">{t("fields")}</span>
                    </div>
                    <ListGroup variant="flush">
                        {fields.map((field, index) => (
                          <ListGroup.Item
                            key={index}
                            className="pl-2 pb-1"
                          >
                            <div>
                              <span className="green-text">
                                {field.internalCode}
                              </span>{" "}
                              - {field.name}
                            </div>
                          </ListGroup.Item>
                        ))}
                    </ListGroup>
                  </div>
                  <div className="col-3">
                    <div className="col-12 light-green-background pt-0">
                      <span className="green-color">{t("subtask")}</span>
                    </div>
                    <ListGroup variant="flush">
                        {subtasks.map((subtask, index) => (
                          <ListGroup.Item
                            key={index}
                            className="pl-2 pb-1"
                          >
                            <div>
                              <span className="green-text">
                                {subtask.internalCode}
                              </span>{" "}
                              - {subtask.name}
                            </div>
                          </ListGroup.Item>
                        ))}
                    </ListGroup>
                  </div>
                  <div className="col-3">
                    <div className="col-12 light-green-background pt-0">
                      <span className="green-color">{t("output")}</span>
                    </div>
                    <ListGroup variant="flush">
                        {outputs.map((output, index) => (
                          <ListGroup.Item
                            key={index}
                            className="pl-2 pb-1"
                          >
                            <div>
                              <span className="green-text">
                                {output.internalCode}
                              </span>{" "}
                              - {output.name}
                            </div>
                          </ListGroup.Item>
                        ))}
                    </ListGroup>
                  </div>
                  <div className="col-3">
                    <div className="col-12 light-green-background pt-0">
                      <span className="green-color">{t("places")}</span>
                    </div>
                    <ListGroup variant="flush">
                        {places.map((place, index) => (
                          <ListGroup.Item
                            key={index}
                            className="pl-2 pb-1"
                          >
                            <div>
                              <span className="green-text">
                                {place.internalCode}
                              </span>{" "}
                              - {place.name}
                            </div>
                          </ListGroup.Item>
                        ))}
                    </ListGroup>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-center text-center">
                <AltchaComponent challengeUrl={challengeAltchaUrl} onVerified={setIsAltchaVerified}/>
              </div>
            </div>
          </FormWizard.TabContent>
        </FormWizard>
      </Modal.Body>
      <ModalCreateResource show={showModalCreateResource} handleClose={closeModalCreateResource} />
    </Modal>   
  );
}

export default ModalCreateProject;
