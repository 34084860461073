import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import { useAuth } from "../../AuthContext";
import { timeslotsApi } from "../../misc/TimeslotsApi";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { enUS, it } from "date-fns/locale";

function ModalCreateProjectFigure({ show, handleClose }) {
  const { i18n, t } = useTranslation();
  const { token, updateToken } = useAuth();
  const rootStyles = getComputedStyle(document.documentElement);
  const mainColor = rootStyles.getPropertyValue("--main-color").trim();
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedFigure, setSelectedFigure] = useState(null);
  const [selectedResource, setSelectedResource] = useState(null);
  const [switches, setSwitches] = useState({
    figureActive: false,
    figureAutogeneration: false,
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [maximumMonthlyReporting, setMaximumMonthlyReporting] = useState("");
  const [projects, setProjects] = useState([]);
  const [figures, setFigures] = useState([]);
  const [resources, setResources] = useState([]);

  const getLocale = () => {
    switch (i18n.language) {
      case "it":
        return it;
      default:
        return enUS;
    }
  };

  const handleSwitch = (field) => {
    setSwitches((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  useEffect(() => {
    const fetchAllData = async () => {
      await updateToken();
      if (token) {
        try {
          const [projectsRes, figuresRes, resourcesRes] = await Promise.all([
            timeslotsApi.getProjectsByDepartment(token),
            timeslotsApi.getAllFigures(token),
            timeslotsApi.getAllResources(token, false),
          ]);

          const formattedProjects = projectsRes.data.map((project) => ({
            value: project.id,
            label: `${project.internalCode} - ${project.name}`,
          }));
          setProjects(formattedProjects);

          const formattedFigures = figuresRes.data.map((figure) => ({
            value: figure.id,
            label: `${figure.externalKey} - ${figure.name}`,
          }));
          setFigures(formattedFigures);

          const formattedResources = resourcesRes.data.map((resource) => ({
            value: resource.id,
            label: resource.company
              ? resource.companyName
              : `${resource.name} ${resource.surname}`,
          }));
          setResources(formattedResources);
        } catch (error) {
          console.error("Error while loading initial data:", error);
        }
      }
    };

    fetchAllData();
  }, [token, updateToken]);

  const resetModal = useCallback(() => {
    setSelectedProject(null);
    setSelectedFigure(null);
    setSelectedResource(null);
    setStartDate(null);
    setEndDate(null);
    setMaximumMonthlyReporting(null);
    setSwitches({
      figureActive: false,
      figureAutogeneration: false,
    });
  }, []);

  useEffect(() => {
    if (!show) {
      resetModal();
    }
  }, [show, resetModal]);

  const handleCreateProjectFigure = async () => {
      const projectFigure = {
        project: selectedProject ? {"id": selectedProject.value} : null,
        figure: selectedFigure ? {"id": selectedFigure.value} : null,
        resource: selectedResource ? {"id": selectedResource.value} : null,
        startDate: startDate || null,
        endDate: endDate || null,
        tslimit: maximumMonthlyReporting || 0,
        active: switches.figureActive,
        autogeneration: switches.figureAutogeneration,
      };
      try {
        await updateToken();
        if (token) {
          await timeslotsApi.createProjectFigure(projectFigure, token);
          toast.success(t('projectFigureSuccessfullySaved'));
          handleClose(); 
        }
      } catch (error) {
        toast.error(t('projectFigureError'));
        console.error("Error while creating project figure:", error);
      }
    };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="medium-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="green-color">
          {t("createProjectFigure")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mx-1">
          <div className="col-4">
            <Form.Group controlId="projectForm">
              <Form.Label className="green-color">{t("project")}</Form.Label>
              <Select
                classNamePrefix="custom-select"
                placeholder={t("project")}
                value={selectedProject}
                options={projects}
                onChange={setSelectedProject}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: mainColor,
                    primary: mainColor,
                    neutral20: "#dee2e6",
                  },
                })}
              />
            </Form.Group>
          </div>
          <div className="col-4">
            <Form.Group controlId="figureForm">
              <Form.Label className="green-color">{t("figure")}</Form.Label>
              <Select
                classNamePrefix="custom-select"
                placeholder={t("figure")}
                value={selectedFigure}
                options={figures}
                onChange={setSelectedFigure}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: mainColor,
                    primary: mainColor,
                    neutral20: "#dee2e6",
                  },
                })}
              />
            </Form.Group>
          </div>
          <div className="col-4">
            <Form.Group controlId="resourceForm">
              <Form.Label className="green-color">
                {t("resource") + " *"}
              </Form.Label>
              <Select
                classNamePrefix="custom-select"
                placeholder={t("resource")}
                value={selectedResource}
                onChange={setSelectedResource}
                options={resources}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: mainColor,
                    primary: mainColor,
                    neutral20: "#dee2e6",
                  },
                })}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row mx-1">
          <div className="col-4">
            <Form.Group controlId="startDate">
              <Form.Label className="green-color">{t("startDate")}</Form.Label>
              <div className="row">
                <div className="col-9 pt-0">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText={t("startDate")}
                    locale={getLocale()}
                    dateFormat="dd/MM/yyyy"
                    className="form-control custom-form-control"
                  />
                </div>
                <div className="col-3 pl-0 pt-0">
                  <img
                    src={`${process.env.PUBLIC_URL}/calendar1.svg`}
                    alt="Calendar"
                    style={{
                      width: "1.5rem",
                    }}
                    className="pt-2"
                  />
                </div>
              </div>
            </Form.Group>
          </div>
          <div className="col-4">
            <Form.Group controlId="endDate">
              <Form.Label className="green-color">{t("endDate")}</Form.Label>
              <div className="row">
                <div className="col-9 pt-0">
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    placeholderText={t("endDate")}
                    locale={getLocale()}
                    dateFormat="dd/MM/yyyy"
                    className="form-control custom-form-control"
                  />
                </div>
                <div className="col-3 pl-0 pt-0">
                  <img
                    src={`${process.env.PUBLIC_URL}/calendar1.svg`}
                    alt="Calendar"
                    style={{
                      width: "1.5rem",
                    }}
                    className="pt-2"
                  />
                </div>
              </div>
            </Form.Group>
          </div>
          <div className="col-4">
            <Form.Group controlId="maximumMonthlyReportingForm">
              <Form.Label className="green-color">
                {t("maximumMonthlyReporting")}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t("maximumMonthlyReporting")}
                className="w-100 custom-form-control"
                value={maximumMonthlyReporting}
                onChange={(e) => setMaximumMonthlyReporting(e.target.value)}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row mx-1">
          <div className="col-6 d-flex justify-content-center">
            <Form.Group controlId="figureActiveSwitchGroup">
              <div className="d-flex align-items-center">
                <Form.Label className="mb-0 mr-3">
                  {t("figureActive") + " *"}
                </Form.Label>
                <div className="d-flex align-items-center">
                  <div
                    className={`mr-2 ${
                      switches.figureActive
                        ? "text-gray"
                        : "text-black-semibold"
                    }`}
                  >
                    OFF
                  </div>
                  <Form.Check
                    type="switch"
                    id="figureActive"
                    className="custom-switch mx-0"
                    checked={switches.figureActive}
                    onChange={() => handleSwitch("figureActive")}
                  />
                  <div
                    className={`mr-2 ${
                      switches.figureActive
                        ? "text-black-semibold"
                        : "text-gray"
                    }`}
                  >
                    ON
                  </div>
                </div>
              </div>
            </Form.Group>
          </div>
          <div className="col-6 d-flex justify-content-center">
            <Form.Group controlId="figureAutogenerationSwitchGroup">
              <div className="d-flex align-items-center">
                <Form.Label className="mb-0 mr-3">
                  {t("selfGenerating") + " *"}
                </Form.Label>
                <div className="d-flex align-items-center">
                  <div
                    className={`mr-2 ${
                      switches.figureAutogeneration
                        ? "text-gray"
                        : "text-black-semibold"
                    }`}
                  >
                    OFF
                  </div>
                  <Form.Check
                    type="switch"
                    id="figureAutogenerationSwitch"
                    className="custom-switch mx-0"
                    checked={switches.figureAutogeneration}
                    onChange={() => handleSwitch("figureAutogeneration")}
                  />
                  <div
                    className={`mr-2 ${
                      switches.figureAutogeneration
                        ? "text-black-semibold"
                        : "text-gray"
                    }`}
                  >
                    ON
                  </div>
                </div>
              </div>
            </Form.Group>
          </div>
        </div>
        <div className="mt-2 container d-flex justify-content-center align-items-center">
          <div className="row">
            <div className="col d-flex justify-content-center align-items-center gap-3">
              <Button
                className="white-text-button fw-semibold"
                disabled={
                  !selectedResource ||
                  !selectedProject ||
                  !selectedFigure ||
                  !startDate ||
                  !endDate ||
                  !switches.figureActive
                }
                onClick={handleCreateProjectFigure}
              >
                {t("save")}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalCreateProjectFigure;
